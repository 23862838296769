import { useEffect, useState } from 'react';

import { SortingDirection } from '../../utils/Sorting';
import SortingArrows from '../SortingArrows/SortingArrows';
import { ColumnDefinitionType, ISortableTable, SortableRow } from './types';

const SortableTable = function <T, K extends keyof T>(props: ISortableTable<T, K>) {
  const findColumnByKey = (key?: string) => {
    return props.columns.find((column) => column.key === key) || null;
  };

  const [columnToSortBy, setColumnToSortBy] = useState<ColumnDefinitionType<T, K> | null>(
    findColumnByKey(props.columnKeyToSortBy || props.defaultSortingKey)
  );

  const [sortingDirection, setSortingDirection] = useState<SortingDirection>(
    props.sortingDirection || props.defaultSortingDirection || 0
  );

  const [sortableRows, setSortableRows] = useState<SortableRow<T>[]>([]);

  useEffect(() => {
    /** Add an iteration index to the data model to use for creating unique keys. */
    const rows: SortableRow<T>[] = props.rows.map((row, idx) => ({
      ...row,
      iterationIndex: idx,
    }));
    setSortableRows(rows);
  }, [props.rows]);

  const renderRow = (row: SortableRow<T>) => {
    return (
      <tr key={`tr-${row[props.columns[0].key]}-${row.iterationIndex}`}>
        {props.columns.map((col, idx) => {
          return (
            <td
              key={'td-' + col.key.toString() + '-' + idx}
              data-col={col.header}
            >
              {col.renderFunction ? col.renderFunction(row) : row[col.key]}
            </td>
          );
        })}
      </tr>
    );
  };

  const updateSorting = (column: ColumnDefinitionType<T, K>, direction: SortingDirection) => {
    setColumnToSortBy(direction !== 0 ? column : null);
    setSortingDirection(direction);
    if (props.onSort) {
      props.onSort(column.key, direction);
    }
  };

  const rows = [...sortableRows]; // Spread to prevent mutation of original array when sorting

  /** Sorting values can (but doesn't necessarily) come from props.
   *  (If e.g. sorting is handled by outside logic.)
   *  If the props exists, they take precedence. */
  const renderColumnToSortBy = findColumnByKey(props.columnKeyToSortBy) || columnToSortBy;
  const renderSortingDirection = props.sortingDirection || sortingDirection;

  /** Don't perform in-component sorting if it is handled externally (props.onSortCallbackHandler exists) */
  if (renderColumnToSortBy && !props.onSort) {
    rows.sort((a, b) => {
      if (renderColumnToSortBy.sortingFunction) {
        // If column has a custom sorting function:
        return renderColumnToSortBy.sortingFunction(a, b, renderSortingDirection);
      } else {
        // if not, use default sorting:
        return (
          String(a[renderColumnToSortBy.key])
            .toLowerCase()
            .localeCompare(String(b[renderColumnToSortBy.key]).toLowerCase()) *
          renderSortingDirection
        );
      }
    });
  }

  return (
    <table>
      <thead>
        <tr>
          {props.columns.map((col, idx) => {
            const colHeadClasses =
              'unicorn-tablehead-content' +
              (col.textAlignHeader ? ' align-' + col.textAlignHeader : '');
            const active = col.key === renderColumnToSortBy?.key;

            return (
              <td key={'th-' + col.key.toString() + '-' + idx}>
                <div className={colHeadClasses}>
                  {col.header}
                  {col.isSortable && (
                    <SortingArrows
                      isActive={active}
                      sortingDirection={active ? renderSortingDirection : SortingDirection.OFF}
                      onClickCallback={(dir: SortingDirection) => {
                        updateSorting(col, dir);
                      }}
                    />
                  )}
                </div>
              </td>
            );
          })}
        </tr>
      </thead>
      <tbody>{rows.map((row) => renderRow(row))}</tbody>
    </table>
  );
};

export default SortableTable;
