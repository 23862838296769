import type { TypePolicies } from '@apollo/client/cache/inmemory/policies';
import { deepEqual } from 'fast-equals';
import { cloneDeep } from 'lodash';

import {
  UpcomingDeliveriesLiveSearchFieldQuery,
  UpcomingDeliveriesLiveSearchFieldQueryVariables,
  UpcomingDeliveriesQuery,
  UpcomingDeliveriesQueryVariables,
} from '../../generated-types/graphql';
import { ExtractElementType } from '../../utils/TypeScriptHelpers';
import { ContextAbstract } from '../ContextAbstract';
import { UPCOMING_DELIVERIES } from './queries/upcomingDeliveries';
import { UPCOMING_DELIVERIES_LIVE_SEARCH_FIELD } from './queries/upcomingDeliveriesLiveSearchField';

export type UpcomingDeliveriesFilters = NonNullable<UpcomingDeliveriesQueryVariables['filter']>;
export type UpcomingDeliveriesList = UpcomingDeliveriesQuery['upcomingDeliveries'];
export type UpcomingDeliveriesItem = ExtractElementType<
  NonNullable<UpcomingDeliveriesQuery['upcomingDeliveries']>['upcomingDeliveries']
>;
export type UpcomingDeliveriesLiveFieldFilters = NonNullable<
  UpcomingDeliveriesLiveSearchFieldQueryVariables['filter']
>;
export type UpcomingDeliveriesShipTos = ExtractElementType<
  NonNullable<UpcomingDeliveriesQuery['getShipTos']>
>;

export class UpcomingDeliveriesDataContext extends ContextAbstract {
  static TypePolicies: TypePolicies = {
    Query: {
      fields: {
        upcomingDeliveries: {
          keyArgs: ['customerId'],
          merge(
            existing: UpcomingDeliveriesList | undefined,
            incoming: UpcomingDeliveriesList,
            { variables }
          ) {
            const merged = existing ? cloneDeep(existing) : cloneDeep(incoming);

            if (incoming && !deepEqual(merged, incoming)) {
              const typedVariables = variables as UpcomingDeliveriesQueryVariables;
              if (
                typedVariables &&
                typedVariables.filter &&
                typedVariables.filter.offset !== null
              ) {
                const { offset = 0 } = typedVariables.filter;
                for (let i = 0; i < incoming.upcomingDeliveries.length; i++) {
                  merged.upcomingDeliveries[offset + i] = incoming.upcomingDeliveries[i];
                }
              }
            }

            return merged;
          },
          read(existing: UpcomingDeliveriesList | undefined) {
            return existing;
          },
        },
      },
    },
  };

  public getAll(customerId: number, filter: UpcomingDeliveriesFilters = { limit: 0, offset: 0 }) {
    return this.queryObservable<
      UpcomingDeliveriesQuery,
      UpcomingDeliveriesQueryVariables,
      UpcomingDeliveriesQueryVariables
    >(UPCOMING_DELIVERIES, { customerId, filter });
  }

  public getAllByLiveSearchField(
    customerId: number,
    filter: UpcomingDeliveriesLiveFieldFilters = { limit: 0, offset: 0 }
  ) {
    return this._apolloClient.watchQuery<
      UpcomingDeliveriesLiveSearchFieldQuery,
      UpcomingDeliveriesLiveSearchFieldQueryVariables
    >({
      query: UPCOMING_DELIVERIES_LIVE_SEARCH_FIELD,
      variables: {
        customerId,
        filter,
      },
    });
  }

  public getAllByLiveSearchFieldLazy() {
    const query = UPCOMING_DELIVERIES_LIVE_SEARCH_FIELD;
    const execute = (customerId: number, filter: UpcomingDeliveriesLiveFieldFilters) =>
      this.getAllByLiveSearchField(customerId, filter);

    return {
      execute,
      query,
    };
  }
}
