import { ApolloError } from '@apollo/client';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { CartReferenceCartType, OrderItem } from '@lego/b2b-unicorn-data-access-layer';
import { OrderType } from '@lego/b2b-unicorn-data-access-layer/generated-types/types';
import { useOrder } from '@lego/b2b-unicorn-data-access-layer/react';
import { ContentHubButton } from '@lego/b2b-unicorn-shared/components/ContentHubButton';
import { Date } from '@lego/b2b-unicorn-shared/components/Date';
import { Price } from '@lego/b2b-unicorn-shared/components/Price';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { useOnPreferredLanguageChange } from '@lego/b2b-unicorn-shared/components/UserContext/hooks';
import { logger } from '@lego/b2b-unicorn-shared/logger';
import {
  ButtonType,
  Container,
  FlexBox,
  Heading,
  PageHeader,
  SubpageHeaderNav,
} from '@lego/b2b-unicorn-shared/ui';
import { NotificationType, useNotifications } from '@lego/b2b-unicorn-ui-components';
import { baseSpacing, media } from '@lego/b2b-unicorn-ui-constants';
import React, { Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import DownloadPdfButton from '../../../components/DownloadPdfButton/DownloadPdfButton';
import InfoWithAdditionalLines from '../../../components/InfoWithAdditionalLines/InfoWithAdditionalLines';
import ProductIdAndImage from '../../../components/ProductIdAndImage/ProductIdAndImage';
import { ProductName } from '../../../components/ProductName/ProductName';
import SkeletonTable from '../../../components/SkeletonLoaders/SkeletonTable';
import SortableTable from '../../../components/SortableTable/SortableTable';
import { ColumnDefinitionType } from '../../../components/SortableTable/types';
import StatusLabel from '../../../components/StatusLabel/StatusLabel';
import { basicPageStyle } from '../../../styles/general';
import { tableStyle } from '../../../styles/table';
import { SortingDirection } from '../../../utils/Sorting';
import {
  CopyOrderItemsToCart,
  CopyOrderItemsToCartButton,
} from '../components/CopyOrderItemsToCart';
import { numericContentStyle, tableContainerStyle } from '../OrderList/styles';
import { orderItemRejectedStatusLabelColors } from '../OrderList/utils';
import OrderStatusSummary from './OrderStatusSummary';
import { orderDetailsPageStyle, orderDetailsTableContainerStyle } from './styles';

interface ILocationState {
  navigatedFromOrderList?: boolean;
}

interface OrderDetailsProps {
  orderNumber: number;
  orderData: ReturnType<typeof useOrder>['data'];
  isLoading: boolean;
}

const itemPriceRenderFunction = (item: OrderItem, locale: string) => {
  return (
    <div css={numericContentStyle}>
      <Price locale={locale}>{item.price}</Price>
    </div>
  );
};

function itemPriceSortingFunction<T extends OrderItem>(a: T, b: T, direction: SortingDirection) {
  return (a.price.estimatedNetInvoicedPrice - b.price.estimatedNetInvoicedPrice) * direction;
}

const OrderDetails: React.FC<OrderDetailsProps> = ({ orderNumber, orderData, isLoading }) => {
  useOnPreferredLanguageChange(['order']);

  const { id: customerId, locale } = useSelectedCustomer();
  const labelsContext = useLabels();
  const {
    getLabelByKey,
    button_copy_items_to_cart,
    copy_items_to_cart_tooltip_disabled,
    button_copy_unfulfilled_items_to_cart,
    copy_unfulfilled_items_to_cart_tooltip_disabled,
  } = labelsContext;

  const location = useLocation<ILocationState>();

  const history = useHistory();

  const { snackbar, addSnackbar } = useNotifications();

  const handlePdfDownloadError = (error: ApolloError) => {
    logger.error(error);
    addSnackbar({
      type: NotificationType.WARNING,
      content: labelsContext.snackbar_download_file_error,
      showDismissButton: true,
      isStacked: !!snackbar,
    });
  };

  const colHeaders: ColumnDefinitionType<OrderItem>[] = [
    {
      key: 'itemNumber',
      header: labelsContext.item_id,
      renderFunction: (row) => (
        <ProductIdAndImage
          materialId={row.materialId}
          itemNumber={row.itemNumber}
        />
      ),
      isSortable: true,
    },
    {
      key: 'name',
      header: labelsContext.name,
      renderFunction: (row) => (
        // <>{row.name}</>
        <ProductName
          name={row.name}
          materialId={
            orderData?.products.products.find((product) => product.materialId === row.materialId) &&
            row.materialId
          }
        />
      ),
    },
    {
      key: 'theme',
      header: labelsContext.theme,
      isSortable: true,
    },
    {
      key: 'orderedPieces',
      header: labelsContext.order_item_ordered,
      renderFunction: (row) => <div css={numericContentStyle}>{row.orderedPieces}</div>,
    },
    {
      key: 'confirmedPieces',
      header: labelsContext.order_item_confirmed,
      renderFunction: (row) => <div css={numericContentStyle}>{row.confirmedPieces}</div>,
    },
    {
      key: 'price',
      header: labelsContext.your_price,
      isSortable: true,
      renderFunction: (row) => itemPriceRenderFunction(row, locale),
      sortingFunction: itemPriceSortingFunction,
    },
    {
      key: 'expectedDeliveryDate',
      header: labelsContext.delivery_date_expected,
      renderFunction: (row) => {
        if (row.rejected) {
          return (
            <StatusLabel
              label={getLabelByKey('status_rejected')}
              colors={orderItemRejectedStatusLabelColors}
            />
          );
        } else if (row.expectedDeliveryDate) {
          return (
            <InfoWithAdditionalLines
              headline={labelsContext.delivery_date_expected}
              lines={row.expectedDeliveryDate.map((delivery) => (
                <>
                  {delivery.pieces} {labelsContext.pieces_by}{' '}
                  <Date locale={locale}>{delivery.date}</Date>
                </>
              ))}
            />
          );
        } else {
          return '-';
        }
      },
    },
  ];

  const materialIds = orderData?.order && orderData.order.items.map((item) => item.materialId);
  const isReplenOrder = orderData?.order?.type === OrderType.OrderTypeReplenishment;

  return (
    <Fragment>
      <PageHeader>
        <SubpageHeaderNav
          goBackText={labelsContext.button_go_to_overview}
          onGoBack={() => {
            if (location.state?.navigatedFromOrderList) {
              /** If the user came here from the list,
               * we want to preserve any filter params
               * when navigating back. */
              history.goBack();
            } else {
              /** If the user came from a deep link to this page,
               * history.goBack() would take them out of our site,
               * so we link directly.
               */
              history.push('/manage');
            }
          }}
        >
          <Heading
            level={1}
            designToken={'text.inverse'}
          >
            {labelsContext.order}: {orderNumber} - {orderData?.order?.orderName}
          </Heading>
        </SubpageHeaderNav>
      </PageHeader>

      <div
        css={[
          basicPageStyle,
          tableStyle,
          tableContainerStyle,
          orderDetailsTableContainerStyle,
          orderDetailsPageStyle,
        ]}
      >
        {orderData?.order !== null && (
          <Fragment>
            <OrderStatusSummary
              order={orderData?.order || undefined}
              locale={locale}
            />

            <Container
              padding={{
                paddingTop: baseSpacing * 3,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: baseSpacing * 3,
              }}
              flex={{ flexDirection: 'column' }}
              flexMedium={{
                [media.medium]: {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                },
              }}
            >
              <Heading
                level={1}
                noMargin
              >
                {labelsContext.order_details}
              </Heading>
              <FlexBox
                flexDirection={'column'}
                gap={baseSpacing}
                flexMedium={{
                  [media.medium]: {
                    flexDirection: 'row',
                    alignItems: 'center',
                  },
                }}
              >
                {materialIds && (
                  <ContentHubButton
                    materialId={materialIds}
                    type={ButtonType.SECONDARY}
                    context={'orderDetails'}
                  />
                )}
                <CopyOrderItemsToCart
                  items={
                    orderData?.order?.items.map((i) => {
                      return {
                        itemId: i.materialId,
                        pieces: i.orderedPieces || 0,
                        name: i.name,
                      };
                    }) || []
                  }
                  cartReference={{ cartType: CartReferenceCartType.Replenish }}
                >
                  <CopyOrderItemsToCartButton
                    disabled={!isReplenOrder}
                    disabledTooltipMessage={copy_items_to_cart_tooltip_disabled}
                    buttonText={button_copy_items_to_cart}
                  />
                </CopyOrderItemsToCart>

                <CopyOrderItemsToCart
                  items={
                    orderData?.order?.items
                      .filter((i) => i.rejectedPieces && i.rejectedPieces > 0)
                      .map((i) => {
                        return {
                          itemId: i.materialId,
                          pieces: i.rejectedPieces || 0,
                          name: i.name,
                        };
                      }) || []
                  }
                  cartReference={{ cartType: CartReferenceCartType.Replenish }}
                >
                  <CopyOrderItemsToCartButton
                    disabled={
                      !isReplenOrder || !orderData?.order?.items.some((i) => i.rejectedPieces)
                    }
                    disabledTooltipMessage={
                      !isReplenOrder
                        ? copy_items_to_cart_tooltip_disabled
                        : copy_unfulfilled_items_to_cart_tooltip_disabled
                    }
                    buttonText={button_copy_unfulfilled_items_to_cart}
                  />
                </CopyOrderItemsToCart>

                {orderData?.order?.hasPdfOutput && (
                  <DownloadPdfButton
                    customerId={customerId}
                    orderNumber={orderNumber}
                    onError={handlePdfDownloadError}
                    type={ButtonType.SECONDARY}
                    size="small"
                  />
                )}
              </FlexBox>
            </Container>
            {isLoading && <SkeletonTable rowsToRender={8} />}

            {orderData?.order && (
              <SortableTable
                columns={colHeaders}
                rows={orderData.order.items}
                defaultSortingKey={'itemNumber'}
                defaultSortingDirection={SortingDirection.ASC}
              />
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default OrderDetails;
