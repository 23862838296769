import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { CartItemRemoved, Money } from '@lego/b2b-unicorn-data-access-layer';
import {
  Button,
  ContentSystemFeedback,
  GoBackLink,
  Spacer,
  SystemFeedbackType,
  Table,
} from '@lego/b2b-unicorn-shared/ui';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { commonSubHeaderStyle, pagePaddingDesktopStyle } from '../../common/styles';
import { CheckoutStep } from '../../common/types';
import CheckoutSteps from '../CheckoutSteps';
import OrderRemovedItems from '../OrderRemovedItems';
import OrderSummary from '../OrderSummary';
import { ResetActionButton } from './ResetActionButton';
import { ResetActionWarning } from './ResetActionWarning';
import { SkeletonItemsTable } from './SkeletonItemsTable';

const subHeaderStyle = css(commonSubHeaderStyle, { height: 90 });

const actionsWrapperStyle = css({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
});

interface IRemoveAllPopupText {
  header: string;
  content: string;
}

interface MinimalOrderItem {
  quantity: number;
  product: {
    materialId: number;
    piecesPerCasePack: number;
    theme: string;
    price: {
      estimatedNetInvoicedPrice: number;
      netInvoicedPrice?: number | null;
      currency: string;
    };
  };
}

interface IOrderPreview<T extends MinimalOrderItem> {
  items: T[];
  itemRowRender: (item: T) => React.ReactNode;
  itemRowHeaderRender: React.ReactNode;
  emptyPreviewFeedbackRender?: React.ReactNode;
  actionsRender?: React.ReactNode;
  itemsLoading: boolean;
  orderPreviewError: boolean;
  isCheckoutDisabled: boolean;
  onGoToCheckoutClick: () => void;
  onResetAction: () => void;
  resetWarningText: IRemoveAllPopupText;
  resetActionType?: 'REMOVE_ALL' | 'RESET';
  removedItems?: CartItemRemoved[] | null;
  closeRemovedItemsPopupHandler?: () => void;
  displayEmptyPreviewFeedback?: boolean;
  readonly?: boolean;
  minimumOrderValue: Money;
}

const OrderPreview = <T extends MinimalOrderItem>({
  items,
  itemRowRender,
  itemRowHeaderRender,
  emptyPreviewFeedbackRender,
  actionsRender,
  itemsLoading,
  orderPreviewError,
  removedItems,
  isCheckoutDisabled,
  onGoToCheckoutClick,
  closeRemovedItemsPopupHandler,
  resetWarningText,
  resetActionType = 'REMOVE_ALL',
  onResetAction,
  readonly = false,
  minimumOrderValue,
}: IOrderPreview<T>) => {
  const [resetActionWarning, setResetActionWarning] = useState(false);
  const { button_checkout, button_go_back, order_details, content_system_feedback_error } =
    useLabels();
  const history = useHistory();

  const orderItemsLength = (items && items.length) || 0;

  const reset = () => {
    onResetAction();
    setResetActionWarning(false);
  };

  const renderResetActionWarning = () => {
    return (
      <ResetActionWarning
        open={resetActionWarning}
        translations={resetWarningText}
        onClose={() => setResetActionWarning(false)}
        onResetAction={reset}
      />
    );
  };

  const renderOrderRemovedItems = () => {
    return (
      !!removedItems &&
      removedItems.length > 0 &&
      closeRemovedItemsPopupHandler && (
        <OrderRemovedItems
          items={removedItems}
          closePopup={closeRemovedItemsPopupHandler}
        />
      )
    );
  };

  return (
    <>
      {!orderPreviewError && (
        <>
          {renderOrderRemovedItems()}
          {!itemsLoading && orderItemsLength === 0 && emptyPreviewFeedbackRender}
          <>
            {(orderItemsLength > 0 || itemsLoading) && (
              <>
                {renderResetActionWarning()}
                {!readonly && (
                  <>
                    <CheckoutSteps checkoutStep={CheckoutStep.PREVIEW} />
                    <OrderSummary
                      cartItems={items}
                      minimumOrderValue={minimumOrderValue}
                      loading={itemsLoading}
                    >
                      {orderItemsLength !== undefined && (
                        <Button
                          onClick={onGoToCheckoutClick}
                          disabled={isCheckoutDisabled}
                        >
                          {button_checkout}
                        </Button>
                      )}
                    </OrderSummary>
                    <Spacer />
                    <div css={[subHeaderStyle, pagePaddingDesktopStyle]}>
                      {order_details}
                      <div css={actionsWrapperStyle}>
                        {actionsRender}
                        <ResetActionButton
                          resetActionType={resetActionType}
                          onClick={() => setResetActionWarning(true)}
                          disabled={orderItemsLength === 0 || itemsLoading}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div css={pagePaddingDesktopStyle}>
                  {itemsLoading ? (
                    <SkeletonItemsTable />
                  ) : (
                    <Table>
                      {itemRowHeaderRender}
                      <tbody>{items && items.map((i) => itemRowRender(i))}</tbody>
                    </Table>
                  )}
                </div>
              </>
            )}
          </>
        </>
      )}
      {orderPreviewError && (
        <ContentSystemFeedback
          type={SystemFeedbackType.ERROR}
          text={content_system_feedback_error}
        >
          <GoBackLink
            onClick={() => history.goBack()}
            text={button_go_back}
          />
        </ContentSystemFeedback>
      )}
    </>
  );
};

export default OrderPreview;
