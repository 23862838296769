import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { Price as PriceType, ProductWithOpenOrderInfo } from '@lego/b2b-unicorn-data-access-layer';
import { Date } from '@lego/b2b-unicorn-shared/components/Date';
import { Price } from '@lego/b2b-unicorn-shared/components/Price';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { AnimatedDots, designToken, DiscountedPrice } from '@lego/b2b-unicorn-shared/ui';
import { OpenOrderIndicator, ProductImage } from '@lego/b2b-unicorn-ui-components';
import { baseSpacing, colors, media } from '@lego/b2b-unicorn-ui-constants';
import { getImageUrl, ImageType } from '@lego/b2b-unicorn-ui-utils';
import { isAfter, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';

import {
  imageWrapperStyle,
  itemIdCellStyle,
  itemIdFontStyle,
  mobileCellWithColDataStyle,
  nameCellStyle,
  tableRowCellStyle,
  tableRowStyle,
} from '../../common/styles';

const checkoutTableRowStyle = css(tableRowStyle, {
  '> td': {
    ...tableRowCellStyle,
  },
  '> td:nth-of-type(n+3)': {
    ...mobileCellWithColDataStyle,
  },
  '> td:nth-of-type(1)': {
    order: 1,
  },
  '> td:nth-of-type(2)': {
    order: 2,
  },
  '> td:nth-of-type(3)': {
    order: 3,
  },
  '> td:nth-of-type(4)': {
    order: 8,
  },
  '> td:nth-of-type(5)': {
    order: 7,
  },
  '> td:nth-of-type(6)': {
    order: 4,
  },
  '> td:nth-of-type(7)': {
    order: 5,
  },
  '> td:last-of-type': {
    order: 6,
  },
});

const tableRowCellNumberStyle = (
  simulationRunning: boolean,
  simulationSuccess?: boolean,
  isDeliveryLate?: boolean
) =>
  css({
    color: simulationSuccess
      ? isDeliveryLate
        ? designToken.warning.emphasis
        : designToken.success.default
      : undefined,
    minHeight: 25,
    fontFeatureSettings: '"tnum"',
    fontVariantNumeric: 'tabular-nums',
    [media.medium]: {
      color: simulationRunning ? colors.klik.slate400 : '',
      backgroundColor: simulationRunning ? colors.klik.slate50 : '',
      textAlign: 'right',
      paddingRight: baseSpacing * 2,
    },
    [media.large]: {
      paddingRight: !simulationRunning ? 20 : '',
    },
  });

const itemIdStyle = css(itemIdCellStyle, {
  justifyContent: 'flex-end',
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
});

interface ICheckoutItemRow {
  product: ProductWithOpenOrderInfo;
  quantity: number;
  simulationRunning: boolean;
  requestedDeliveryDate?: string;
  expectedDeliveryDate?: string | null;
  simulationPricePerPiece?: PriceType;
  isLaunchProduct: boolean;
}

const CheckoutItemRow = ({
  product,
  requestedDeliveryDate,
  expectedDeliveryDate,
  simulationPricePerPiece,
  quantity,
  simulationRunning,
  isLaunchProduct,
}: ICheckoutItemRow) => {
  const selectedCustomer = useSelectedCustomer();
  const {
    theme: theme_label,
    delivery_date_expected,
    price_per_piece,
    quantity: quantity_label,
    total_pieces,
    your_price,
    open_order_dialog_header,
    delivery_date_expected_next,
    total_pending,
    pcs,
    pcs_by,
  } = useLabels();

  const totalPieces = quantity * product.piecesPerCasePack;
  const simulationSuccess = !!expectedDeliveryDate || !!simulationPricePerPiece;
  const [isDeliveryLate, setIsDeliveryLate] = useState<boolean>(false);

  const imageUrl = !isLaunchProduct ? getImageUrl(product.materialId, ImageType.MAIN_BOX, 30) : '';

  useEffect(() => {
    if (expectedDeliveryDate && requestedDeliveryDate) {
      setIsDeliveryLate(isAfter(parseISO(expectedDeliveryDate), parseISO(requestedDeliveryDate)));
    }
  }, [expectedDeliveryDate, requestedDeliveryDate]);

  return (
    <tr css={checkoutTableRowStyle}>
      <td>
        <div css={itemIdStyle}>
          <div css={imageWrapperStyle}>
            <ProductImage
              src={imageUrl}
              alt="boxImage"
            />
          </div>
          <span css={itemIdFontStyle}>{product.__typename === 'Box' && product.itemNumber}</span>
        </div>
      </td>
      <td css={nameCellStyle}>
        {product.name}{' '}
        {product.openOrderInfo && (
          <OpenOrderIndicator
            isSmall
            shippingDate={product.openOrderInfo.shippingDate}
            shippingPieces={product.openOrderInfo.shippingPieces}
            totalPendingPieces={product.openOrderInfo.totalPendingPieces}
            locale={selectedCustomer.locale}
            translations={{
              open_order_dialog_header,
              delivery_date_expected_next,
              total_pending,
              pcs,
              pcs_by,
            }}
          />
        )}
      </td>
      <td data-col={theme_label}>{product.theme}</td>
      <td
        data-testid="item-expected-delivery-date"
        data-col={delivery_date_expected}
        css={tableRowCellNumberStyle(simulationRunning, simulationSuccess, isDeliveryLate)}
      >
        {simulationRunning ? (
          <AnimatedDots data-testid="dots" />
        ) : (
          expectedDeliveryDate && (
            <Date locale={selectedCustomer.locale}>{expectedDeliveryDate}</Date>
          )
        )}
      </td>
      <td
        data-col={price_per_piece}
        css={tableRowCellNumberStyle(simulationRunning, simulationSuccess)}
      >
        {simulationRunning ? (
          <AnimatedDots data-testid="dots" />
        ) : simulationPricePerPiece ? (
          <Price locale={selectedCustomer.locale}>{simulationPricePerPiece}</Price>
        ) : (
          <DiscountedPrice locale={selectedCustomer.locale}>{product.price}</DiscountedPrice>
        )}
      </td>
      <td
        data-col={quantity_label}
        css={tableRowCellNumberStyle(false)}
      >
        {quantity}
      </td>
      <td
        data-col={total_pieces}
        css={tableRowCellNumberStyle(false)}
      >
        {totalPieces}
      </td>
      <td
        data-col={your_price}
        css={tableRowCellNumberStyle(simulationRunning, simulationSuccess)}
      >
        {simulationRunning ? (
          <AnimatedDots data-testid="dots" />
        ) : simulationPricePerPiece ? (
          <Price
            locale={selectedCustomer.locale}
            multiplier={totalPieces}
          >
            {simulationPricePerPiece}
          </Price>
        ) : (
          <DiscountedPrice
            locale={selectedCustomer.locale}
            multiplier={totalPieces}
          >
            {product.price}
          </DiscountedPrice>
        )}
      </td>
    </tr>
  );
};

export default CheckoutItemRow;
