import { ApolloError } from '@apollo/client';
import type { FetchResult } from '@apollo/client/link/core';
import { formatISO9075 } from 'date-fns';
import { GraphQLFormattedError } from 'graphql/index';
import { map } from 'rxjs';

import {
  BrickBankPaymentMethodsQuery,
  BrickBankPaymentMethodsQueryVariables,
  CartQuery,
  CartQueryVariables,
  CartWithSimulationDetailsQuery,
  CartWithSimulationDetailsQueryVariables,
  CheckoutCartQuery,
  CheckoutCartQueryVariables,
  CheckoutRecommendationsQuery,
  CheckoutRecommendationsQueryVariables,
  CreateBrickBankOrderMutation,
  CreateBrickBankOrderMutationVariables,
  CreateOrderMutation,
  CreateOrderMutationVariables,
  EmptyCartMutation,
  EmptyCartMutationVariables,
  RemoveBrickBankStoredCardMutation,
  RemoveBrickBankStoredCardMutationVariables,
  UpdateCartMutation,
  UpdateCartMutationVariables,
} from '../../generated-types/graphql';
import {
  BrickBankPaymentInfoInput,
  CartReferenceInput,
  SimulationPriceDetails,
} from '../../generated-types/types';
import { UPDATE_CART } from '../../type-policies/CartPolicy';
import { ExtractElementType } from '../../utils/TypeScriptHelpers';
import { ContextAbstract, MutationObservable } from '../ContextAbstract';
import { ApolloErrorWithExtensions, ExtraOptions } from '../GenericContextTypes';
import { CREATE_BRICK_BANK_ORDER } from './mutations/createBrickBankOrder';
import { CREATE_ORDER } from './mutations/createOrder';
import { EMPTY_CART } from './mutations/emptyCart';
import { REMOVE_BRICK_BANK_STORED_CARD } from './mutations/removeBrickBankStoredCard';
import { BRICK_BANK_PAYMENT_METHODS } from './queries/brickBankPaymentMethods';
import { CART } from './queries/cart';
import { CART_WITH_SIMULATION_DETAILS } from './queries/cartWithSimulationDetails';
import { CHECKOUT_CART } from './queries/checkoutCart';
import { CHECKOUT_RECOMMENDATIONS } from './queries/checkoutRecommendations';

type PaymentResultCode = 'Refused' | 'Cancelled' | 'Error' | 'RedirectShopper';
type PaymentRefusalReasonCodes = '2' | '5' | '6' | '8' | '14' | '20' | '22' | '24' | string;

export enum PaymentErrorCodes {
  PaymentRefused = 'PAYMENT_REFUSED',
  PaymentCancelled = 'PAYMENT_CANCELLED',
  PaymentRedirectShopper = 'PAYMENT_REDIRECTSHOPPER',
  PaymentError = 'PAYMENT_ERROR',
}

type PaymentErrorExtensions = {
  code: PaymentErrorCodes;
  resultCode?: PaymentResultCode;
  paymentError?: {
    resultCode: PaymentResultCode;
    refusalReason: string;
    refusalReasonCode: PaymentRefusalReasonCodes;
  };
};

export type PaymentApolloError = ApolloErrorWithExtensions<PaymentErrorExtensions>;

export type CartItem =
  | ExtractElementType<CartQuery['getCart']['items']>
  | ExtractElementType<CartWithSimulationDetailsQuery['cartWithSimulationDetails']['items']>;
export type CartItemRemoved = ExtractElementType<CartQuery['getCart']['removedItems']>;
export type Product = CartItem['product'];
export type CartSimulationDetails = SimulationPriceDetails;
export type CheckoutRecommendationItem = ExtractElementType<
  CheckoutRecommendationsQuery['recommendations']
>;

export class CheckoutDataContext extends ContextAbstract {
  public getCart(
    customerId: number,
    cartReference: CartReferenceInput,
    removeObsoleteItems: boolean = true,
    extraOptions?: ExtraOptions
  ) {
    return this._apolloClient.watchQuery<CartQuery, CartQueryVariables>({
      query: CART,
      variables: {
        customerId,
        cartReference,
        removeObsoleteItems,
      },
      ...extraOptions,
    });
  }

  public updateCart(
    customerId: number,
    cartReference: CartReferenceInput,
    removeObsoleteItems: boolean = true,
    optimistic: boolean = false,
    onError?: (
      error: Error | readonly Error[] | GraphQLFormattedError | readonly GraphQLFormattedError[]
    ) => void
  ): MutationObservable<
    UpdateCartMutation,
    { quantity: number; productOrMaterialId: number | Product }
  > {
    if (!this.cartPolicy) {
      throw Error(`CheckoutDataContext: typePolicy is not defined`);
    }

    const observableKey = `updateCart-${customerId}-${cartReference.cartType}`;

    const variables = {
      customerId,
      cartReference,
      removeObsoleteItems,
    };

    const [mutate, reset, results] = this.mutationObservable<
      UpdateCartMutation,
      UpdateCartMutationVariables
    >(observableKey, UPDATE_CART, 'materialId', onError);
    const updateCartOptimistic = this.cartPolicy.updateCartOptimistic.bind(this);

    return [
      ({ quantity, productOrMaterialId }) => {
        if (optimistic && typeof productOrMaterialId !== 'number') {
          updateCartOptimistic(productOrMaterialId, quantity, {
            query: CART,
            variables: {
              customerId,
              cartReference,
              removeObsoleteItems,
            },
          });
        }

        return mutate({
          ...variables,
          materialId:
            typeof productOrMaterialId === 'number'
              ? productOrMaterialId
              : productOrMaterialId.materialId,
          quantity,
        });
      },
      reset,
      results,
    ];
  }

  public get isUpdatingCart() {
    return this.activeMutationAbortControllers.pipe(
      map((activeMutationControllers) => activeMutationControllers > 0)
    );
  }

  public emptyCart(
    customerId: number,
    cartReference: CartReferenceInput,
    optimistic: boolean = false
  ) {
    if (optimistic) {
      this.cartPolicy?.emptyCartOptimistic({
        query: CART,
        variables: {
          customerId,
          cartReference,
          removeObsoleteItems: true,
        },
      });
    }

    return this._apolloClient.mutate<EmptyCartMutation, EmptyCartMutationVariables>({
      mutation: EMPTY_CART,
      variables: {
        customerId,
        cartReference,
      },
    });
  }

  public emptyCartLazy(customerId: number, cartReference: CartReferenceInput) {
    const mutation = EMPTY_CART;
    const execute = () => this.emptyCart(customerId, cartReference);

    return {
      execute,
      mutation,
    };
  }

  public cartWithSimulationDetails(
    customerId: number,
    shipToId: number,
    requestedDeliveryDate: Date,
    cartReference: CartReferenceInput,
    extraOptions?: ExtraOptions
  ) {
    const formattedDate = formatISO9075(requestedDeliveryDate, { representation: 'date' });

    return this._apolloClient.watchQuery<
      CartWithSimulationDetailsQuery,
      CartWithSimulationDetailsQueryVariables
    >({
      query: CART_WITH_SIMULATION_DETAILS,
      variables: {
        customerId,
        cartReference,
        requestedDeliveryDate: formattedDate,
        shipToId,
      },
      ...extraOptions,
    });
  }

  public cartWithSimulationDetailsLazy(
    customerId: number,
    cartReference: CartReferenceInput,
    extraOptions?: ExtraOptions
  ) {
    const query = CART_WITH_SIMULATION_DETAILS;
    const execute = (shipToId: number, requestedDeliveryDate: Date) =>
      this.cartWithSimulationDetails(
        customerId,
        shipToId,
        requestedDeliveryDate,
        cartReference,
        extraOptions
      );

    return {
      execute,
      query,
    };
  }

  public checkoutCart(
    customerId: number,
    cartReference: CartReferenceInput,
    removeObsoleteItems: boolean = false,
    extraOptions?: ExtraOptions
  ) {
    return this._apolloClient.watchQuery<CheckoutCartQuery, CheckoutCartQueryVariables>({
      query: CHECKOUT_CART,
      variables: {
        customerId,
        cartReference,
        removeObsoleteItems,
      },
      ...extraOptions,
    });
  }

  public recommendations(customerId: number) {
    return this.queryObservable<
      CheckoutRecommendationsQuery,
      CheckoutRecommendationsQueryVariables
    >(CHECKOUT_RECOMMENDATIONS, { customerId }, { fetchPolicy: 'no-cache' });
  }

  public brickBankPaymentMethods(customerId: number, extraOptions?: ExtraOptions) {
    return this._apolloClient.watchQuery<
      BrickBankPaymentMethodsQuery,
      BrickBankPaymentMethodsQueryVariables
    >({
      query: BRICK_BANK_PAYMENT_METHODS,
      variables: {
        customerId,
      },
      ...extraOptions,
    });
  }

  public removeBrickBankStoredCard(
    customerId: number
  ): MutationObservable<RemoveBrickBankStoredCardMutation, { cardId: string }> {
    if (!this.cartPolicy) {
      throw new Error('CheckoutDataContext has not been created with a type policy!');
    }

    const observableKey = `removeBrickBankStoredCard-${customerId}`;

    const [mutate, reset, results] = this.mutationObservable<
      RemoveBrickBankStoredCardMutation,
      RemoveBrickBankStoredCardMutationVariables
    >(observableKey, REMOVE_BRICK_BANK_STORED_CARD);

    return [
      ({ cardId }) => {
        const variables: RemoveBrickBankStoredCardMutationVariables = {
          customerId,
          cardId,
        };
        return mutate({ ...variables });
      },
      reset,
      results,
    ];
  }

  public async createOrder(
    customerId: number,
    shipToId: number,
    requestedDeliveryDate: Date,
    cartReference: CartReferenceInput,
    orderName: string,
    brickBankPaymentInfo?: BrickBankPaymentInfoInput | undefined
  ): Promise<FetchResult<CreateOrderMutation>>;
  public async createOrder(
    customerId: number,
    shipToId: number,
    requestedDeliveryDate: Date,
    cartReference: CartReferenceInput,
    orderName: string,
    brickBankPaymentInfo: BrickBankPaymentInfoInput
  ): Promise<FetchResult<CreateBrickBankOrderMutation>>;
  public async createOrder(
    customerId: number,
    shipToId: number,
    requestedDeliveryDate: Date,
    cartReference: CartReferenceInput,
    orderName: string,
    brickBankPaymentInfo?: BrickBankPaymentInfoInput | undefined
  ) {
    const formattedRequestedDeliveryDate = formatISO9075(requestedDeliveryDate, {
      representation: 'date',
    });

    if (brickBankPaymentInfo) {
      try {
        const result = await this._apolloClient.mutate<
          CreateBrickBankOrderMutation,
          CreateBrickBankOrderMutationVariables
        >({
          mutation: CREATE_BRICK_BANK_ORDER,
          variables: {
            customerId,
            shipToId,
            requestedDeliveryDate: formattedRequestedDeliveryDate,
            orderName,
            brickBankPaymentInfo,
            cartReference,
          },
        });

        if (result && !result.errors) {
          this.emptyCart(customerId, cartReference, true);
        }

        return result;
      } catch (error) {
        if (error instanceof ApolloError) {
          throw error as PaymentApolloError;
        }

        throw error;
      }
    } else {
      const result = await this._apolloClient.mutate<
        CreateOrderMutation,
        CreateOrderMutationVariables
      >({
        mutation: CREATE_ORDER,
        variables: {
          customerId,
          shipToId,
          requestedDeliveryDate: formattedRequestedDeliveryDate,
          orderName,
          cartReference,
        },
      });

      if (result && !result.errors) {
        this.emptyCart(customerId, cartReference, true);
      }

      return result;
    }
  }
}
