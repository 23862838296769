import { cloneDeep } from '@apollo/client/utilities';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import {
  CartReferenceCartType,
  LaunchCartProduct,
  LaunchCartReferenceInput,
} from '@lego/b2b-unicorn-data-access-layer';
import { WindowType } from '@lego/b2b-unicorn-data-access-layer/generated-types/types';
import {
  useIsUpdatingCart,
  useLaunchWindow,
  useOptimisticEmptyLaunchWindow,
  useOptimisticUpdateLaunchWindow,
} from '@lego/b2b-unicorn-data-access-layer/react';
import { hasNotFoundError } from '@lego/b2b-unicorn-data-access-layer/utils/hasNotFoundError';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { useOnPreferredLanguageChange } from '@lego/b2b-unicorn-shared/components/UserContext/hooks';
import {
  BodyWrapper,
  Card,
  Container,
  EmptyFeedback,
  GoBackLink,
  Paragraph,
  Spacer,
} from '@lego/b2b-unicorn-shared/ui';
import { ILaunchWindowReference, OrderPreview } from '@lego/b2b-unicorn-ui-checkout-flow';
import { baseSpacing, ProductTableColumnType } from '@lego/b2b-unicorn-ui-constants';
import { CartMath, mapToMoney, sortAscending, sortDescending } from '@lego/b2b-unicorn-ui-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { localizedWindowName } from '../../../../utils/localizedWindowName';
import {
  LaunchItemRow,
  LaunchOrderBlockedItems,
  LaunchTableHeader,
  LaunchWindowHeader,
} from './components';
import { LaunchWindowBulkAddAction } from './components/LaunchWindowBulkAddAction';

interface LaunchWindowProps {
  launchWindowReference: ILaunchWindowReference;
  launchWindowData: ReturnType<typeof useLaunchWindow>['data'];
  launchWindowError: ReturnType<typeof useLaunchWindow>['error'];
  isLoading: boolean;
  onCartUpdateError: () => void;
}

const LaunchWindowDetails: React.FC<LaunchWindowProps> = ({
  launchWindowReference,
  launchWindowData,
  launchWindowError,
  onCartUpdateError,
  isLoading,
}) => {
  // Component internal required properties
  const selectedCustomer = useSelectedCustomer();
  const {
    launch_window_remove_all_header,
    launch_window_remove_all_content,
    launch_window_no_products,
    launch_window_go_back,
  } = useLabels();
  const history = useHistory();

  // Components internal states
  // By default, sort items by item id ascending.
  const [activeSortingField, setActiveSortingField] = useState<ProductTableColumnType>(
    ProductTableColumnType.ITEM_ID
  );
  const [isAscending, setIsAscending] = useState<boolean>(true);
  const [isCheckoutDisabled, setIsCheckoutDisabled] = useState(true);
  const isCartUpdating = useIsUpdatingCart();

  const cartReference = useMemo<LaunchCartReferenceInput>(() => {
    return {
      cartType: CartReferenceCartType.Launch,
      launch: {
        year: launchWindowReference.year,
        month: launchWindowReference.month,
      },
    };
  }, [launchWindowReference]);
  const minimumOrderValue =
    (launchWindowData && launchWindowData.getCart.minimumOrderValue) || mapToMoney(0, '');

  useOnPreferredLanguageChange(['launchWindow']);

  const {
    mutate: updateCart,
    error: updateLaunchWindowError,
    loading: updateCartLoading,
  } = useOptimisticUpdateLaunchWindow(
    selectedCustomer.id,
    launchWindowReference.year,
    launchWindowReference.month
  );
  const { mutate: emptyCart } = useOptimisticEmptyLaunchWindow(
    selectedCustomer.id,
    launchWindowReference.year,
    launchWindowReference.month
  );

  const updateCartError = useMemo(() => {
    if (updateLaunchWindowError) {
      // In case of error, we want to refetch the cart to get the latest data
      onCartUpdateError();
      return true;
    }

    return false;
  }, [onCartUpdateError, updateLaunchWindowError]);

  const handleItemUpdate = useCallback(
    (product: LaunchCartProduct, quantity: number) => {
      setIsCheckoutDisabled(true);
      updateCart({
        productOrMaterialId: product,
        quantity,
      });
    },
    [updateCart]
  );

  const handleRemoveAll = () => {
    emptyCart();
  };

  const handleMultipleItemsUploadError = useCallback(() => {
    if (isLoading) {
      return;
    }
    onCartUpdateError();
  }, [isLoading, onCartUpdateError]);

  const items = useMemo(() => {
    if (!launchWindowData) {
      return [];
    }

    const clonedItems = cloneDeep(launchWindowData.launchWindow.products).map((p) => {
      const itemInCart = launchWindowData.getCart.items.find((i) => {
        if (!i.product) {
          return false;
        }

        return i.product.materialId === p.materialId;
      });

      return {
        product: p,
        quantity: itemInCart?.quantity || 0,
      };
    });

    return isAscending
      ? sortAscending(clonedItems, activeSortingField)
      : sortDescending(clonedItems, activeSortingField);
  }, [activeSortingField, isAscending, launchWindowData]);

  useEffect(() => {
    if (updateCartLoading || !items || isCartUpdating) {
      setIsCheckoutDisabled(true);
    } else {
      const totalPrice = CartMath.sumEstimatedNetInvoicedPrice(items).estimatedNetInvoicedPrice;
      setIsCheckoutDisabled(totalPrice > 0 ? totalPrice < minimumOrderValue.amount : true);
    }
  }, [items, updateCartLoading, selectedCustomer, minimumOrderValue, isCartUpdating]);

  const orderPreviewError = !!launchWindowError && !hasNotFoundError(launchWindowError);

  const hasBlockedItems =
    launchWindowData?.launchWindow.orderBlockedProducts &&
    launchWindowData?.launchWindow.orderBlockedProducts.length > 0;

  const windowIsNotOpen = launchWindowData
    ? launchWindowData?.launchWindow.windowType !== WindowType.Open
    : false;

  const handleGoToCheckoutClick = useCallback(() => {
    history.push(`/launch/${launchWindowReference.year}/${launchWindowReference.month}/checkout`);
  }, [history, launchWindowReference.month, launchWindowReference.year]);

  return (
    <BodyWrapper>
      <LaunchWindowHeader
        launchDate={localizedWindowName(
          launchWindowReference.month,
          launchWindowReference.year,
          selectedCustomer.locale
        )}
        orderDeadlineDate={launchWindowData?.launchWindow?.orderEndInstant}
        windowType={launchWindowData?.launchWindow?.windowType}
      />
      <Container padding={{ paddingBottom: baseSpacing * 15 }}>
        <Card>
          <OrderPreview
            readonly={windowIsNotOpen}
            items={items}
            itemsLoading={isLoading}
            itemRowRender={(i) => (
              <LaunchItemRow
                key={`${i.product.materialId}`}
                product={i.product}
                quantity={i.quantity}
                onUpdate={handleItemUpdate}
                updateError={updateCartError}
                inactive={windowIsNotOpen}
              />
            )}
            itemRowHeaderRender={
              <LaunchTableHeader
                activeSortingField={activeSortingField}
                setActiveSortingField={setActiveSortingField}
                isAscending={isAscending}
                setIsAscending={setIsAscending}
                inactive={windowIsNotOpen}
              />
            }
            emptyPreviewFeedbackRender={
              <EmptyFeedback type={'construction'}>
                <Paragraph>{launch_window_no_products}</Paragraph>
                <GoBackLink
                  text={launch_window_go_back}
                  onClick={() => history.push('/launch')}
                />
              </EmptyFeedback>
            }
            actionsRender={
              !windowIsNotOpen && (
                <LaunchWindowBulkAddAction
                  launchWindowProducts={launchWindowData?.launchWindow.products || []}
                  cartReference={cartReference}
                  disabled={isLoading}
                  onError={handleMultipleItemsUploadError}
                />
              )
            }
            orderPreviewError={orderPreviewError}
            isCheckoutDisabled={isCheckoutDisabled}
            onGoToCheckoutClick={handleGoToCheckoutClick}
            resetWarningText={{
              header: launch_window_remove_all_header,
              content: launch_window_remove_all_content,
            }}
            onResetAction={handleRemoveAll}
            minimumOrderValue={minimumOrderValue}
            displayEmptyPreviewFeedback={true}
          />
          {!hasBlockedItems && <Spacer />}
          {hasBlockedItems && (
            <LaunchOrderBlockedItems
              products={launchWindowData?.launchWindow.orderBlockedProducts}
            />
          )}
        </Card>
      </Container>
    </BodyWrapper>
  );
};

export default LaunchWindowDetails;
