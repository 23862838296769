/* eslint-disable @typescript-eslint/no-explicit-any */
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

export class SubjectsRegistry {
  private readonly _behaviors: Map<string, BehaviorSubject<any>> = new Map();
  private readonly _replays: Map<string, ReplaySubject<any>> = new Map();
  private readonly _subjects: Map<string, Subject<any>> = new Map();

  constructor() {
    if (process.env.NODE_ENV === 'development') {
      (window as any).subjectsRegistry = this;
    }
  }

  public addBehavior<T>(key: string, suffix: string, initialValue: T): void {
    this._behaviors.set(this.mergeKey(key, suffix), new BehaviorSubject<T>(initialValue));
  }

  public behavior<T>(key: string, suffix: string, initialValue?: T): BehaviorSubject<T> {
    const mergedKey = this.mergeKey(key, suffix);
    if (!this._behaviors.has(mergedKey)) {
      if (initialValue === undefined) {
        throw new Error(
          `Behavior subject with key ${mergedKey} does not exist, and there is no initial value provided to create it`
        );
      }
      this.addBehavior(key, suffix, initialValue);
    }
    return this._behaviors.get(mergedKey) as BehaviorSubject<T>;
  }

  public addReplay<T>(key: string, suffix: string, bufferSize?: number): void {
    this._replays.set(this.mergeKey(key, suffix), new ReplaySubject<T>(bufferSize));
  }

  public replay<T>(key: string, suffix: string, bufferSize?: number): ReplaySubject<T> {
    const mergedKey = this.mergeKey(key, suffix);
    if (!this._replays.has(mergedKey)) {
      this.addReplay(key, suffix, bufferSize);
    }

    return this._replays.get(mergedKey) as ReplaySubject<T>;
  }

  public addSubject<T>(key: string, suffix: string): void {
    this._subjects.set(this.mergeKey(key, suffix), new Subject<T>());
  }

  public subject<T>(key: string, suffix: string): Subject<T> {
    const mergedKey = this.mergeKey(key, suffix);
    if (!this._subjects.has(mergedKey)) {
      this.addSubject(key, suffix);
    }

    return this._subjects.get(mergedKey) as Subject<T>;
  }

  private mergeKey(key: string, value: string): string {
    return `${key}-${value}`;
  }
}
