import { GraphQLFormattedError } from 'graphql/index';
import { map } from 'rxjs';

import { CartReferenceCartType } from '../../';
import {
  EmptyCartMutation,
  EmptyCartMutationVariables,
  PromotionQuery,
  PromotionQueryVariables,
  PromotionsQuery,
  PromotionsQueryVariables,
  UpdateCartMutation,
  UpdateCartMutationVariables,
  UpdatePromotionWithMultipleItemsMutation,
  UpdatePromotionWithMultipleItemsMutationVariables,
} from '../../generated-types/graphql';
import { PromotionCartReference } from '../../generated-types/types';
import { UPDATE_CART } from '../../type-policies/CartPolicy';
import { EMPTY_CART } from '../../type-policies/CartPolicy/mutations/emptyCart';
import { ExtractElementType } from '../../utils/TypeScriptHelpers';
import {
  ContextAbstract,
  MutationObservable,
  QueryObservable,
  QueryObservableOptions,
} from '../ContextAbstract';
import { FRAGMENT_PROMOTION_OVERVIEW_ITEM } from './fragments/PromotionOverviewItem';
import { UPDATE_PROMOTION_WITH_MULTIPLE_ITEMS } from './mutations/updatePromotionWithMultipleItems';
import { PROMOTION } from './queries/promotion';
import { PROMOTIONS } from './queries/promotions';

export type PromotionCartReferenceInput = {
  cartType: CartReferenceCartType.Promotion;
  promotion: PromotionCartReference;
};
export type OrderPromotionsOverviewItem = ExtractElementType<
  PromotionsQuery['promotions']['availableForOrderPromotions']
>;
export type PromotionProduct = ExtractElementType<PromotionQuery['promotion']['products']>;
export type PromotionProductCondition = ExtractElementType<
  PromotionQuery['promotion']['productConditions']
>;
export type Promotion = PromotionQuery;

type CartItem = ExtractElementType<UpdatePromotionWithMultipleItemsMutationVariables['items']>;
export type CartItemWithPromotionProduct = Omit<CartItem, 'materialId'> & {
  product: PromotionProduct;
};

export class PromotionsDataContext extends ContextAbstract {
  static Fragments = [FRAGMENT_PROMOTION_OVERVIEW_ITEM];

  public getPromotions(customerId: number): QueryObservable<PromotionsQuery> {
    return this.queryObservable<PromotionsQuery, PromotionsQueryVariables>(
      PROMOTIONS,
      { customerId },
      { fetchPolicy: 'network-only' }
    );
  }

  public getPromotion(
    customerId: number,
    promotionId: number,
    extraOptions?: QueryObservableOptions<PromotionQuery>
  ) {
    return this.queryObservable<PromotionQuery, PromotionQueryVariables>(
      PROMOTION,
      { customerId, promotionId },
      extraOptions
    );
  }

  public updatePromotionProductInCart(
    customerId: number,
    promotionId: number,
    productOrMaterialId: number | PromotionProduct,
    optimistic: boolean = false,
    onError?: (
      error: Error | readonly Error[] | GraphQLFormattedError | readonly GraphQLFormattedError[]
    ) => void
  ): MutationObservable<UpdateCartMutation, { quantity: number }> {
    if (!this.cartPolicy) {
      throw new Error('PromotionsDataContext has not been created with a type policy!');
    }

    const materialId =
      typeof productOrMaterialId === 'number'
        ? productOrMaterialId
        : productOrMaterialId.materialId;
    const observableKey = `updatePromotionCart-${customerId}-${promotionId}-${materialId}`;
    const cartReference: PromotionCartReferenceInput = {
      cartType: CartReferenceCartType.Promotion,
      promotion: { promotionId },
    };

    const variables: UpdateCartMutationVariables = {
      customerId,
      materialId,
      quantity: 1,
      cartReference,
    };

    const [mutate, reset, results] = this.mutationObservable<
      UpdateCartMutation,
      UpdateCartMutationVariables
    >(observableKey, UPDATE_CART, 'materialId', onError);
    const updateCartOptimistic = this.cartPolicy.updateCartOptimistic.bind(this);

    return [
      ({ quantity }) => {
        if (optimistic && typeof productOrMaterialId !== 'number') {
          updateCartOptimistic(productOrMaterialId, quantity, {
            query: PROMOTION,
            variables: {
              customerId,
              promotionId,
            },
          });
        }

        return mutate({
          ...variables,
          quantity,
        });
      },
      reset,
      results,
    ];
  }

  public get isUpdatingPromotionCart() {
    return this.activeMutationAbortControllers.pipe(
      map((activeMutationControllers) => activeMutationControllers > 0)
    );
  }

  public emptyPromotionCart(
    customerId: number,
    promotionId: number,
    optimistic: boolean = false,
    onError?: (
      error: Error | readonly Error[] | GraphQLFormattedError | readonly GraphQLFormattedError[]
    ) => void
  ): MutationObservable<EmptyCartMutation> {
    if (!this.cartPolicy) {
      throw new Error('PromotionsDataContext has not been created with a type policy!');
    }

    const observableKey = `emptyPromotion-${customerId}-${promotionId}`;
    const [mutate, reset, results] = this.mutationObservable<
      EmptyCartMutation,
      EmptyCartMutationVariables
    >(observableKey, EMPTY_CART, undefined, onError);
    const emptyCartOptimistic = this.cartPolicy.emptyCartOptimistic.bind(this);

    return [
      () => {
        if (optimistic) {
          emptyCartOptimistic({
            query: PROMOTION,
            variables: {
              customerId,
              promotionId,
            },
          });
        }

        return mutate({
          customerId,
          cartReference: {
            cartType: CartReferenceCartType.Promotion,
            promotion: {
              promotionId,
            },
          },
        });
      },
      reset,
      results,
    ];
  }

  public updatePromotionWithMultipleItems(
    customerId: number,
    promotionId: number,
    optimistic: boolean = false,
    onError?: (
      error: Error | readonly Error[] | GraphQLFormattedError | readonly GraphQLFormattedError[]
    ) => void
  ): MutationObservable<
    UpdatePromotionWithMultipleItemsMutation,
    { items: CartItemWithPromotionProduct[] }
  > {
    if (!this.cartPolicy) {
      throw new Error('PromotionsDataContext has not been created with a type policy!');
    }

    const observableKey = `updatePromotionWithMultipleItems-${customerId}-${promotionId}`;
    const [mutate, reset, results] = this.mutationObservable<
      UpdatePromotionWithMultipleItemsMutation,
      UpdatePromotionWithMultipleItemsMutationVariables
    >(observableKey, UPDATE_PROMOTION_WITH_MULTIPLE_ITEMS, 'promotionId', onError);

    const updateCartWithMultipleOptimistic =
      this.cartPolicy.updateCartWithMultipleOptimistic.bind(this);

    return [
      ({ items }) => {
        if (optimistic) {
          updateCartWithMultipleOptimistic(items, {
            query: PROMOTION,
            variables: {
              customerId,
              promotionId,
            },
          });
        }

        return mutate({
          customerId,
          promotionId,
          items: items.map((item) => ({
            materialId: item.product.materialId,
            quantity: item.quantity,
          })),
        });
      },
      reset,
      results,
    ];
  }
}
