import { css } from '@emotion/react';
import { deepEqual } from 'fast-equals';
import React, { useCallback, useEffect, useMemo } from 'react';

import { CurrencyInput } from '../../ui/CurrencyInput';
import { SelectBox } from '../../ui/SelectBox';
import { Textarea } from '../../ui/Textarea';
import { baseSpacing } from '../../ui/theme';
import { Typography } from '../../ui/Typography';
import { Counter } from '../Counter';
import { FileUpload } from '../FileUpload';
import { Price } from '../Price';
import { ClaimFormFieldHelperText, ClaimFormFieldLabel, useClaim } from './';
import { useClaimItemFormContext } from './ClaimItemFormContext';
import { ClaimItem } from './ClaimProvider';
import { claimTypes } from './validation';

const inputWidth = { width: 200 };
const formStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: baseSpacing * 3,
  minHeight: 513,
});
const customWidthStyle = css({
  input: inputWidth,
});

type ClaimItemFormFieldsProps = {
  claimItem?: ClaimItem;
  getFileUploadUrl: React.ComponentProps<typeof FileUpload>['getFileUploadUrl'];
};
type Files = Parameters<React.ComponentProps<typeof FileUpload>['onChange']>[0];

export const ClaimItemFormFields: React.FC<ClaimItemFormFieldsProps> = ({ getFileUploadUrl }) => {
  const {
    locale,
    translations: { labels, helperTexts, claimTypeOptions },
    invoiceItem,
    formik,
    resetForm,
  } = useClaimItemFormContext();
  const { fileService } = useClaim();
  const fileServiceStack = invoiceItem.invoiceLineNumber.toString();

  const dropdownValues = useMemo(
    () =>
      claimTypes.map((type) => {
        return {
          displayText: claimTypeOptions[type],
          value: type,
        };
      }),
    [claimTypeOptions]
  );

  const handleFilesOnChange = useCallback(
    (files: Files) => {
      const nextDocumentationFiles = files.map((file) => {
        return {
          id: file.metadata['uuid'],
          status: file.status,
          fileSize: file.file.size,
        };
      });

      if (!deepEqual(nextDocumentationFiles, formik.values.documentationFiles)) {
        formik.setFieldValue('documentationFiles', nextDocumentationFiles);
      }
    },
    [formik]
  );

  const onCounterChange = (e: React.ChangeEvent<HTMLInputElement>, isValid?: boolean) => {
    formik.setFieldTouched('pieces', true, true);
    formik.setFieldValue('pieces', parseInt(e.target.value, 10));
    formik.validateField('pieces');
    // To validate claimed pieces do not exceed invoiced pieces (We don't want to use dynamic values on validation schema)
    // Cannot use setFieldError as it is being overridden with formik.validate right after validateField
    formik.setStatus(isValid ? undefined : { materialIdOverMaxPieces: invoiceItem.materialId });
  };

  useEffect(() => {
    if (formik.values.claimType === 'PRICE_DISPUTES' && formik.values.expectedPrice === '') {
      formik.setFieldValue('expectedPrice', 0);
    } else if (
      formik.values.claimType !== 'PRICE_DISPUTES' &&
      typeof formik.values.expectedPrice === 'number'
    ) {
      formik.setFieldValue('expectedPrice', '');
    }
  }, [formik]);

  return (
    <div css={formStyle}>
      <div>
        <ClaimFormFieldLabel>{labels.claimType} *</ClaimFormFieldLabel>
        <SelectBox
          label={helperTexts.claimType}
          values={dropdownValues}
          selectedValues={formik.values.claimType}
          optionsChangeHandler={(value) => {
            resetForm();
            formik.setFieldValue('claimType', value);
            if (value === 'PRICE_DISPUTES') {
              formik.setFieldValue('pieces', invoiceItem.pieces);
            }
          }}
          allowMultiple={false}
        />
      </div>
      {formik.values.claimType && (
        <>
          {formik.values.claimType !== 'PRICE_DISPUTES' && (
            <div>
              <ClaimFormFieldLabel>{labels.claimedPieces} *</ClaimFormFieldLabel>
              <Counter
                value={formik.values.pieces || 0}
                onChange={onCounterChange}
                minValue={0}
                maxValue={
                  formik.values.claimType !== 'OVER_UNDER_SUB' ? invoiceItem.pieces : undefined
                }
                isInvalid={
                  !!formik.touched.pieces &&
                  (!!formik.errors.pieces ||
                    formik.status?.materialIdOverMaxPieces === invoiceItem.materialId)
                }
                invalidMessage={
                  (formik.errors.pieces as string) || 'Claimed pieces exceeds invoiced pieces'
                }
                customStyle={css(inputWidth)}
              />
              <ClaimFormFieldHelperText>{`${helperTexts.claimedPieces}: ${invoiceItem.pieces}`}</ClaimFormFieldHelperText>
            </div>
          )}
          {formik.values.claimType === 'PRICE_DISPUTES' && (
            <div css={customWidthStyle}>
              <ClaimFormFieldLabel>{labels.expectedPrice} *</ClaimFormFieldLabel>
              <CurrencyInput
                {...formik.getFieldProps('expectedPrice')}
                textAlign={'right'}
                onChange={(value) => formik.setFieldValue('expectedPrice', value)}
                isInvalid={!!formik.touched.expectedPrice && !!formik.errors.expectedPrice}
                invalidMessage={formik.errors.expectedPrice as string}
                currency={invoiceItem.piecePrice.currency}
                locale={locale}
              />
              <ClaimFormFieldHelperText>
                {`${helperTexts.expectedPrice}: `}
                <Price locale={locale}>{invoiceItem.piecePrice}</Price>
              </ClaimFormFieldHelperText>
            </div>
          )}
          <div>
            <ClaimFormFieldLabel>
              {labels.comment}{' '}
              {formik.values.claimType === 'PRICE_DISPUTES' && '*'}
            </ClaimFormFieldLabel>
            <Textarea
              rows={2}
              isInvalid={!!formik.touched.comment && !!formik.errors.comment}
              invalidMessage={formik.errors.comment as string}
              {...formik.getFieldProps('comment')}
            />
          </div>
          <div>
            <ClaimFormFieldLabel>
              {labels.uploadDocumentation} {formik.values.claimType === 'DAMAGED_GOODS' && '*'}
            </ClaimFormFieldLabel>
            <FileUpload
              fileService={fileService}
              fileServiceStack={fileServiceStack}
              onChange={handleFilesOnChange}
              getFileUploadUrl={getFileUploadUrl}
              translations={{
                file_upload_drag_and_drop: helperTexts.uploadDocumentationDragAndDrop,
                file_upload_browse_files: helperTexts.uploadDocumentationBrowse,
                status: {
                  fileSizeExceeded: helperTexts.uploadFileStatusFileSizeExceeded,
                  unsupported: helperTexts.uploadFileStatusUnsupported,
                  failed: helperTexts.uploadFileStatusFailed,
                  cancelled: helperTexts.uploadFileStatusCancelled,
                },
              }}
              acceptedFileTypes={['image/*', '.heic', '.heif', 'application/pdf']}
              multiple
            />
          </div>
        </>
      )}
      <Typography
        size={'small'}
        weight={'semibold'}
        designToken={'text.informative'}
      >
        * {helperTexts.requiredFields}
      </Typography>
    </div>
  );
};
