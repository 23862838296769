import { gql } from '@apollo/client';

export const CHECKOUT_RECOMMENDATIONS = gql`
  query checkoutRecommendations($customerId: Int!) {
    recommendations(customerId: $customerId) {
      materialId
      name
      theme
      recommendedRetailPrice {
        currency
        amount
      }
      piecesPerCasePack
      price {
        currency
        estimatedNetInvoicedPrice
        grossPrice
      }
      stockStatus {
        status
        expectedBackDate
      }
      openOrderInfo {
        shippingDate
        shippingPieces
        totalPendingPieces
      }
      itemNumber
    }
  }
`;
