import { DocumentNode } from 'graphql/language/ast';

export const getQueryName = (query: DocumentNode): string => {
  if (query.definitions.length === 0) {
    throw new Error('getQueryName: query has no definitions.');
  }

  if (query.definitions.length > 1) {
    console.warn('getQueryName: query has more than one definition, returning the first one.');
  }

  if (
    query.definitions[0].kind === 'SchemaDefinition' ||
    query.definitions[0].kind === 'SchemaExtension'
  ) {
    throw new Error('getQueryName: query is a schema definition.');
  }

  if (query.definitions[0].name === undefined) {
    throw new Error('getQueryName: query has no name.');
  }

  return query.definitions[0].name.value;
};
