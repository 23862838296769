import { NetworkStatus } from '@apollo/client';
import { useApmTransaction } from '@lego/b2b-unicorn-apm/ApmPageTransaction';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import {
  UpcomingDeliveriesFilters,
  UpcomingDeliveriesItem,
} from '@lego/b2b-unicorn-data-access-layer';
import { useUpcomingDeliveries } from '@lego/b2b-unicorn-data-access-layer/react';
import { Date } from '@lego/b2b-unicorn-shared/components/Date';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { useOnPreferredLanguageChange } from '@lego/b2b-unicorn-shared/components/UserContext/hooks';
import { ContentSystemFeedback, SystemFeedbackType } from '@lego/b2b-unicorn-shared/ui';
import { LoadMoreButton } from '@lego/b2b-unicorn-ui-components';
import { removeEmptyFromObject, useStateWithUrl } from '@lego/b2b-unicorn-ui-utils';
import { deepEqual } from 'fast-equals';
import React, { Fragment, useEffect, useRef, useState } from 'react';

import ProductIdAndImage from '../../../components/ProductIdAndImage/ProductIdAndImage';
import { ProductName } from '../../../components/ProductName/ProductName';
import SkeletonTable from '../../../components/SkeletonLoaders/SkeletonTable';
import SortableTable from '../../../components/SortableTable/SortableTable';
import { ColumnDefinitionType } from '../../../components/SortableTable/types';
import { TABLE_ROWS_PER_PAGE } from '../../../constants';
import { basicPageStyle } from '../../../styles/general';
import { tableStyle } from '../../../styles/table';
import { FiltersInURL } from '../../../utils/DateRanges';
import {
  getSortingColumKeyFromUrlParam,
  getSortingDirectionFromUrlParam,
  SortingDirection,
} from '../../../utils/Sorting';
import { orderDetailsTableContainerStyle } from '../OrderDetails/styles';
import { numericContentStyle, tableContainerStyle } from '../OrderList/styles';
import { parseOrderFilterParams } from '../OrderList/utils';
import DeliveriesSearchAndFilters from './DeliveriesSearchAndFilters';

const UpcomingDeliveries = () => {
  useOnPreferredLanguageChange(['UpcomingDeliveries']);
  const { endTransaction, startTransaction } = useApmTransaction();
  const [loadMoreInProgress, setLoadMoreInProgress] = useState(false);
  const [selectedFilters, setSelectedFilters] =
    useStateWithUrl<FiltersInURL<UpcomingDeliveriesFilters>>();
  const [initialFilters] = useState(() => {
    if (selectedFilters?.offset) {
      delete selectedFilters.offset;
      setSelectedFilters(selectedFilters);
    }

    return selectedFilters;
  });
  const previousFilters = useRef(initialFilters);

  const selectedCustomer = useSelectedCustomer();
  const labelsContext = useLabels();

  const {
    refetch,
    fetchMore,
    error,
    data,
    status: networkStatus,
    loading,
  } = useUpcomingDeliveries(
    selectedCustomer.id,
    initialFilters
      ? {
          ...removeEmptyFromObject(parseOrderFilterParams(initialFilters)),
          limit: TABLE_ROWS_PER_PAGE,
          offset: initialFilters.offset || 0,
        }
      : undefined
  );

  useEffect(() => {
    if ((data || error) && !loading && networkStatus !== NetworkStatus.setVariables) {
      endTransaction && endTransaction();
    }
  }, [data, endTransaction, error, loading, networkStatus]);

  const handleLoadMoreClick = () => {
    if (totalCount > (selectedFilters?.offset || TABLE_ROWS_PER_PAGE)) {
      const updatedFilters = { ...selectedFilters };
      updatedFilters.limit = TABLE_ROWS_PER_PAGE;
      updatedFilters.offset = data?.upcomingDeliveries.upcomingDeliveries.length;
      setSelectedFilters(updatedFilters);
      setLoadMoreInProgress(true);
      startTransaction && startTransaction();
      fetchMore({
        customerId: selectedCustomer.id,
        filter: {
          ...removeEmptyFromObject(parseOrderFilterParams(updatedFilters)),
        },
      }).finally(() => {
        setLoadMoreInProgress(false);
      });
    }
  };

  const handleSortingChange = (key: keyof UpcomingDeliveriesItem, direction: SortingDirection) => {
    const updatedFilters = { ...selectedFilters };
    updatedFilters.offset = 0;
    updatedFilters.limit = TABLE_ROWS_PER_PAGE;

    if (direction === SortingDirection.OFF) {
      delete updatedFilters.sort;
    } else {
      updatedFilters.sort = [`${key}:${SortingDirection[direction].toLowerCase()}`];
    }
    setSelectedFilters(updatedFilters);
    startTransaction && startTransaction();
  };

  const handleFilterChange = (nextSelectedFilters: typeof selectedFilters) => {
    const updatedFilters = { ...nextSelectedFilters };
    updatedFilters.offset = 0;
    updatedFilters.limit = TABLE_ROWS_PER_PAGE;

    setSelectedFilters(updatedFilters);

    startTransaction && startTransaction();
  };

  useEffect(() => {
    if (selectedFilters?.offset !== 0 && selectedFilters?.offset !== undefined) {
      return;
    }

    if (!deepEqual(selectedFilters, previousFilters.current)) {
      refetch({
        filter: {
          ...removeEmptyFromObject(parseOrderFilterParams(selectedFilters)),
        },
        customerId: selectedCustomer.id,
      });
      previousFilters.current = selectedFilters;
    }
  }, [refetch, selectedCustomer.id, selectedFilters]);

  const colHeaders: ColumnDefinitionType<UpcomingDeliveriesItem>[] = [
    {
      key: 'itemNumber',
      header: labelsContext.item_id,
      isSortable: true,
      renderFunction: (row) => (
        <ProductIdAndImage
          materialId={row.materialId}
          itemNumber={row.itemNumber}
        />
      ),
    },
    {
      key: 'itemName',
      header: labelsContext.name,
      renderFunction: (row) => (
        <ProductName
          name={row.itemName}
          materialId={
            data?.products.products.find((product) => product.materialId === row.materialId) &&
            row.materialId
          }
        />
      ),
    },
    {
      key: 'orderNumber',
      header: labelsContext.order_number,
      isSortable: true,
    },
    {
      key: 'orderName',
      header: labelsContext.order_name,
      isSortable: true,
    },
    {
      key: 'shipTo',
      header: labelsContext.ship_to,
      renderFunction: (row) => `${row.shipTo.id} ${row.shipTo.city}`,
    },
    {
      key: 'orderedPieces',
      header: labelsContext.order_item_ordered,
      renderFunction: (row) => <div css={numericContentStyle}>{row.orderedPieces}</div>,
    },
    {
      key: 'expectedDeliveryDate',
      header: labelsContext.order_item_confirmed,
      renderFunction: (row) => (
        <div css={numericContentStyle}>{row.expectedDeliveryDate.pieces}</div>
      ),
    },
    {
      key: 'expectedDeliveryDate',
      header: labelsContext.delivery_date_expected,
      isSortable: true,
      renderFunction: (row) => (
        <div css={numericContentStyle}>
          <Date locale={selectedCustomer.locale}>{row.expectedDeliveryDate.date}</Date>
        </div>
      ),
    },
  ];

  const initialLoading = networkStatus === NetworkStatus.loading;
  const refetching =
    networkStatus === NetworkStatus.refetch || networkStatus === NetworkStatus.setVariables;
  const fetchingMore = networkStatus === NetworkStatus.fetchMore;

  const sortFromUrlParam = selectedFilters?.sort?.slice(0, 1).pop();
  const currentOffset = selectedFilters?.offset || 0;
  const totalCount = data?.upcomingDeliveries?.totalCount || 0;

  const showTable = (!initialLoading && !refetching) || fetchingMore;
  const showLoadMore =
    (!error &&
      !initialLoading &&
      !refetching &&
      totalCount > (currentOffset + TABLE_ROWS_PER_PAGE || TABLE_ROWS_PER_PAGE)) ||
    loadMoreInProgress;

  return (
    <div css={[basicPageStyle, tableStyle, orderDetailsTableContainerStyle]}>
      <DeliveriesSearchAndFilters
        selectedFilters={selectedFilters}
        updateCallback={handleFilterChange}
        customerId={selectedCustomer.id}
        locale={selectedCustomer.locale}
        shipTos={data?.getShipTos || []}
      />
      <div css={tableContainerStyle}>
        {(initialLoading || refetching) && (
          <Fragment>
            <h2>{labelsContext.upcoming_deliveries}</h2>
            <SkeletonTable rowsToRender={10} />
          </Fragment>
        )}
        {error ? (
          <ContentSystemFeedback
            type={SystemFeedbackType.ERROR}
            text={labelsContext.error_occurred}
          />
        ) : (
          data &&
          data.upcomingDeliveries &&
          data.upcomingDeliveries.upcomingDeliveries.length === 0 && (
            <ContentSystemFeedback text={labelsContext.quick_order_search_no_results} />
          )
        )}
        {data &&
          data.upcomingDeliveries &&
          data.upcomingDeliveries.upcomingDeliveries.length > 0 &&
          showTable && (
            <Fragment>
              <h2>
                {labelsContext.upcoming_deliveries} ({data.upcomingDeliveries.totalCount})
              </h2>
              <SortableTable
                columns={colHeaders}
                rows={data.upcomingDeliveries.upcomingDeliveries}
                onSort={handleSortingChange}
                columnKeyToSortBy={getSortingColumKeyFromUrlParam(sortFromUrlParam)}
                sortingDirection={getSortingDirectionFromUrlParam(sortFromUrlParam)}
              />
            </Fragment>
          )}
      </div>
      {showLoadMore && (
        <LoadMoreButton
          handleLoadMoreClick={handleLoadMoreClick}
          loadMoreInProgress={loadMoreInProgress}
          text={labelsContext.button_load_more}
        />
      )}
    </div>
  );
};

export default UpcomingDeliveries;
