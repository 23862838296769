import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { ReplenishmentProduct } from '@lego/b2b-unicorn-data-access-layer';
import { useOnPreferredLanguageChange } from '@lego/b2b-unicorn-shared/components/UserContext/hooks';
import React, { useState } from 'react';

import { ContainerHeader } from '../../ContainerHeader';
import { ProductDetailsTile } from './';

interface IProductDetails {
  product: ReplenishmentProduct;
  locale: string;
}

export const ProductDetails = ({ product, locale }: IProductDetails) => {
  const [open, setOpen] = useState(true);
  const { product_details } = useLabels();

  useOnPreferredLanguageChange(['replenishmentProduct']);

  return (
    <div css={{ marginBottom: 60 }}>
      <ContainerHeader
        text={product_details}
        open={open}
        setOpen={setOpen}
        display={false}
        withLargeText={false}
      />
      <ProductDetailsTile
        product={product}
        open={open}
        locale={locale}
      />
    </div>
  );
};
