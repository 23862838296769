/* eslint-disable @typescript-eslint/ban-ts-comment,no-console,no-useless-escape */
import type { FilterSimpleTransaction } from '@lego/b2b-unicorn-apm';
import { ApmService } from '@lego/b2b-unicorn-apm';
import { createLogger, Logger, SeverityLevel } from '@lego/b2b-unicorn-shared/logger';
import { v4 as uuid } from 'uuid';

import { jsonStringifyWithDepthLimit } from './utils/jsonStringifyWithDepthLimit';

const APP_ENVIRONMENT = process.env.APP_ENVIRONMENT;
const APP_VERSION = process.env.APP_VERSION;
window.__DEV__ = process.env.NODE_ENV !== 'production';

const loggerOnLog: NonNullable<ConstructorParameters<typeof Logger>[0]>['onLog'] = (
  loggerName,
  level,
  message,
  extra
) => {
  let transaction = ApmService.instance?.getCurrentTransaction();
  if (!transaction) {
    transaction = ApmService.instance?.startTransaction(
      `${location.pathname}${location.search}`,
      'app'
    );
  }

  // @ts-ignore
  transaction?.block(true);
  const safeError =
    typeof message === 'string' ? message : JSON.parse(jsonStringifyWithDepthLimit(message, 10));

  const labels = {
    logger_name: loggerName,
    full_message: typeof safeError === 'string' ? safeError : JSON.stringify(safeError),
    level: SeverityLevel[level],
  };
  if (extra) {
    const normalizedExtra = {};
    for (const [key, value] of Object.entries(extra)) {
      Object.assign(normalizedExtra, {
        [key]: typeof value === 'string' ? value : jsonStringifyWithDepthLimit(value, 10),
      });
    }
    Object.assign(labels, normalizedExtra);
  }

  const logSpan = transaction?.startSpan(labels.full_message.slice(0, 120), 'console', {
    blocking: true,
  });
  logSpan?.addLabels(labels);
  window.setTimeout(() => {
    logSpan?.end();
  });

  if (typeof message !== 'string' && !(message instanceof Error)) {
    Object.assign(safeError, { name: safeError?.message });
  }
  switch (level) {
    case SeverityLevel.fatal:
    case SeverityLevel.error:
      // @ts-ignore
      ApmService.instance?.captureError(safeError);
      window.__DEV__ && console.error(safeError);
      return;
    case SeverityLevel.warning:
      window.__DEV__ && console.warn(safeError);
      return;
    case SeverityLevel.log:
      window.__DEV__ && console.log(safeError);
      return;
    case SeverityLevel.info:
      window.__DEV__ && console.info(safeError);
      return;
    case SeverityLevel.debug:
      window.__DEV__ && console.debug(safeError);
      return;
    default:
      window.__DEV__ && console.log(`[${level}]`, safeError);
      return;
  }
};

declare global {
  interface Window {
    __session_id__: string;
  }
}
window.__session_id__ = uuid();

createLogger({
  onLog: loggerOnLog,
  level: window.__DEV__ ? SeverityLevel.debug : SeverityLevel.info,
});

if ((APP_ENVIRONMENT && APP_VERSION) || APP_ENVIRONMENT === 'local') {
  ApmService.initialize(
    {
      environment: process.env.APP_ENVIRONMENT,
      serviceName: process.env.APM_SERVICE_NAME,
      serviceVersion: process.env.APP_VERSION,
      serverUrl: process.env.APM_SERVER_URL,
      distributedTracingOrigins: process.env.APM_DISTRIBUTED_TRACING_ORIGINS?.split('||'),
      disableInstrumentations: ['history', 'click'],
      propagateTracestate: true,
      logLevel: 'info',
      breakdownMetrics: true,
    },
    { team: 'b2b-webshop', session_id: window.__session_id__ }
  );

  ApmService.instance?.addFilter((payload) => {
    if (Array.isArray(payload.errors) && payload.errors.length > 0) {
      payload.errors = payload.errors.filter((error: unknown) => {
        // @ts-ignore
        switch (error.exception?.type) {
          /**
           * Ignored errors:
           * * EmptyError is a harmless error, we do not want it in our error logs
           * * NotReadableError is a known error when it fails to send data to ES, we do not want it in our error logs
           */
          case 'EmptyError':
          case 'NotReadableError':
            return false;
        }

        /**
         * NotReadableError sometimes appear as a "TypeError", look for it and ignore it
         */
        if (
          // @ts-ignore
          error.exception?.type === 'TypeError' &&
          // @ts-ignore
          error.exception?.message?.includes('NotReadableError')
        ) {
          return false;
        }

        return true;
      });
    }

    if (Array.isArray(payload.transactions) && payload.transactions.length > 0) {
      // Filter out transactions with update-checker and outcome of success
      payload.transactions = payload.transactions.filter((transaction: FilterSimpleTransaction) => {
        if (
          transaction.name &&
          transaction.name.endsWith('update-checker.json') &&
          transaction.outcome === 'success'
        ) {
          return false;
        }

        return true;
      });

      /**
       * Redact login #state value from logs
       */
      payload.transactions.forEach((transaction: FilterSimpleTransaction) => {
        const statePattern = /#state=[\w\d\.=%&-_]+/;
        if (transaction.name && transaction.name.includes('#state=')) {
          transaction.name = transaction.name.replace(statePattern, '#state=REDACTED');
        }

        if (transaction.context?.page?.url && transaction.context?.page?.url.includes('#state=')) {
          transaction.context.page.url = transaction.context.page.url.replace(
            statePattern,
            '#state=REDACTED'
          );
        }
      });
    }

    return payload;
  });
}
