import { css } from '@emotion/react';
// Eslint seems to have a problem with named imports of types. TODO: Update to version 8, perhaps?
// eslint-disable-next-line import/named
import { CSSInterpolation } from '@emotion/serialize';
import { baseSpacing, media, mediaSizes } from '@lego/b2b-unicorn-ui-constants';

import { topRowOfOrderItemStyle } from '../OrderList/styles';

export const invoiceListStyles: CSSInterpolation = {
  table: {
    tbody: {
      tr: {
        td: {
          verticalAlign: 'top',
          [media.medium]: {
            div: {
              lineHeight: 'inherit',
            },
          },
        },
        'td:first-of-type': {
          height: 55,
          [media.medium]: {
            height: 'auto',
          },
        },
      },
    },
  },
};

export const invoiceListTableResponsivenessStyle = css({
  [`@media not all and (min-width: ${mediaSizes.medium}px)`]: {
    'table tbody tr td': {
      order: 3,
      '&:nth-of-type(1)': [
        topRowOfOrderItemStyle,
        {
          order: 1,
          flexGrow: 1,
          fontSize: 16,
        },
      ],
      '&:nth-of-type(6)': [
        topRowOfOrderItemStyle,
        {
          order: 2,
        },
      ],
      '&:nth-of-type(2)': {
        paddingTop: baseSpacing,
      },
    },
  },
});
