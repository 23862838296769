/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { NetworkStatus } from '@apollo/client';
import { useApmTransaction } from '@lego/b2b-unicorn-apm/ApmPageTransaction';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { useLaunchWindowsSummary } from '@lego/b2b-unicorn-data-access-layer/react';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { useOnPreferredLanguageChange } from '@lego/b2b-unicorn-shared/components/UserContext/hooks';
import {
  BodyWrapper,
  Container,
  ContentSystemFeedback,
  Disclaimer,
  EmptyFeedback,
  Paragraph,
  Section,
  SectionTitle,
  SkeletonListTile,
  Spacer,
  SystemFeedbackType,
  TileGrid,
} from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing } from '@lego/b2b-unicorn-ui-constants';
import { TopbarImplementation } from '@lego/b2b-unicorn-ui-topbar';
import React, { useEffect, useMemo } from 'react';

import { Banner } from '../../../shared/Components/Notifications';
import { LaunchWindowsBulkQuickAddAction } from './components/LaunchWindowsOverview/LaunchWindowsBulkQuickAddAction';
import {
  FutureLaunchWindowTile,
  OpenLaunchWindowTile,
  PreviousLaunchWindowTile,
} from './components/LaunchWindowsOverview/tiles';

interface ISortableLaunchWindow {
  year: number;
  month: number;
}
const sortLaunchWindows = (w1: ISortableLaunchWindow, w2: ISortableLaunchWindow) => {
  const w1SortableDate = parseInt(`${w1.year}${w1.month.toString().padStart(2, '0')}`);
  const w2SortableDate = parseInt(`${w2.year}${w2.month.toString().padStart(2, '0')}`);

  return w1SortableDate - w2SortableDate;
};

const LaunchWindowsOverview = () => {
  const {
    launch_windows_upcoming,
    launch_windows_previous,
    launch_windows_future,
    launch_window_future_disclaimer,
    content_system_feedback_error,
    launch_window_no_windows,
  } = useLabels();
  const selectedCustomer = useSelectedCustomer();
  const { endTransaction } = useApmTransaction();
  useOnPreferredLanguageChange(['launchWindowsOverview']);

  const {
    data: launchWindowSummaryResponse,
    error: launchWindowsSummaryError,
    status: launchWindowsSummaryStatus,
    loading: launchWindowsSummaryIsLoading,
    refetch,
  } = useLaunchWindowsSummary(selectedCustomer.id, {
    cacheKey: `launchWindowsOverview-${selectedCustomer.id}`,
  });

  const launchWindows = useMemo(() => {
    if (!launchWindowSummaryResponse) {
      return null;
    }

    const launchWindowSummaryResponseCopy: typeof launchWindowSummaryResponse = JSON.parse(
      JSON.stringify(launchWindowSummaryResponse)
    );

    launchWindowSummaryResponseCopy.launchWindows.futureWindows?.sort(sortLaunchWindows);
    launchWindowSummaryResponseCopy.launchWindows.previousWindows.sort(sortLaunchWindows);
    launchWindowSummaryResponseCopy.launchWindows.openWindows.sort(sortLaunchWindows);

    return launchWindowSummaryResponseCopy.launchWindows;
  }, [launchWindowSummaryResponse]);

  useEffect(() => {
    if (launchWindowsSummaryError || launchWindowSummaryResponse) {
      endTransaction && endTransaction();
    }
  }, [endTransaction, launchWindowSummaryResponse, launchWindowsSummaryError]);

  const openWindowsCount =
    launchWindows?.openWindows && launchWindows?.openWindows.length > -1
      ? launchWindows?.openWindows.length
      : null;
  const showOpenWindows =
    launchWindowsSummaryStatus !== NetworkStatus.loading &&
    openWindowsCount !== null &&
    openWindowsCount > 0;
  const noActiveWindows = !launchWindowsSummaryIsLoading && openWindowsCount === 0;

  return (
    <BodyWrapper>
      <TopbarImplementation />
      <Banner />
      <Container padding={{ paddingBottom: baseSpacing * 15 }}>
        {!launchWindowsSummaryError && (
          <Section main>
            <SectionTitle>{launch_windows_upcoming}</SectionTitle>
            {showOpenWindows ? (
              <LaunchWindowsBulkQuickAddAction onCartUpdated={refetch} />
            ) : (
              <Spacer multiplier={10} />
            )}
            {!launchWindowsSummaryError && launchWindowsSummaryIsLoading && (
              <TileGrid>
                <SkeletonListTile />
              </TileGrid>
            )}
            {showOpenWindows && (
              <>
                {!launchWindowsSummaryIsLoading && (
                  <TileGrid>
                    {launchWindows?.openWindows.map((window) => {
                      return (
                        <OpenLaunchWindowTile
                          openLaunchWindow={window}
                          key={`active-${window.year}-${window.month}`}
                        />
                      );
                    })}
                  </TileGrid>
                )}
              </>
            )}
            {noActiveWindows && (
              <EmptyFeedback type={'rocket'}>
                <Paragraph>{launch_window_no_windows}</Paragraph>
              </EmptyFeedback>
            )}
          </Section>
        )}
        {!launchWindowsSummaryIsLoading &&
          launchWindows &&
          launchWindows.previousWindows?.length > 0 && (
            <Section>
              <SectionTitle>{launch_windows_previous}</SectionTitle>
              <TileGrid>
                {launchWindows.previousWindows.map((window) => {
                  return (
                    <PreviousLaunchWindowTile
                      key={`inactive-${window.year}-${window.month}`}
                      previousLaunchWindow={window}
                    />
                  );
                })}
              </TileGrid>
            </Section>
          )}
        {launchWindows &&
          launchWindows.futureWindows &&
          launchWindows.futureWindows?.length > 0 && (
            <>
              <Spacer />
              <Section>
                <SectionTitle>{launch_windows_future}</SectionTitle>
                <Disclaimer text={launch_window_future_disclaimer} />
                <TileGrid>
                  {launchWindows.futureWindows.map((window) => {
                    return (
                      <FutureLaunchWindowTile
                        key={`inactive-${window.year}-${window.month}`}
                        futureLaunchWindow={window}
                      />
                    );
                  })}
                </TileGrid>
              </Section>
            </>
          )}
        {launchWindowsSummaryError && (
          <ContentSystemFeedback
            type={SystemFeedbackType.ERROR}
            text={content_system_feedback_error}
          />
        )}
      </Container>
    </BodyWrapper>
  );
};

export default LaunchWindowsOverview;
