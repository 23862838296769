import type { NormalizedCacheObject } from '@apollo/client';
import { HttpLink } from '@apollo/client';
import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { createFragmentRegistry } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { mergeDeep } from '@apollo/client/utilities';
import { ApmService } from '@lego/b2b-unicorn-apm';
import { PromotionsDataContext } from '@lego/b2b-unicorn-data-access-layer/context/Promotions/PromotionsDataContext';
import { ProductPolicy } from '@lego/b2b-unicorn-data-access-layer/type-policies/ProductPolicy';
import { normalizeGraphQLError } from '@lego/b2b-unicorn-shared/helpers';
import { Logger } from '@lego/b2b-unicorn-shared/logger';

import { BootstrapDataContext } from './context/Bootstrap/BootstrapDataContext';
import { CheckoutDataContext } from './context/Checkout/CheckoutDataContext';
import { ClaimsDataContext } from './context/Claims/ClaimsDataContext';
import { FooterDataContext } from './context/Footer/FooterDataContext';
import { InfoDataContext } from './context/Info/InfoDataContext';
import { InvoicesDataContext } from './context/Invoices/InvoicesDataContext';
import { LaunchWindowsDataContext } from './context/LaunchWindows/LaunchWindowsDataContext';
import { OrdersDataContext } from './context/Orders/OrdersDataContext';
import { ReplenishmentDataContext } from './context/Replenishment/ReplenishmentDataContext';
import { TopbarDataContext } from './context/Topbar/TopbarDataContext';
import { UpcomingDeliveriesDataContext } from './context/UpcomingDeliveries/UpcomingDeliveriesDataContext';
import { UserDataContext } from './context/User/UserDataContext';
import { UtilsDataContext } from './context/Utils/UtilsDataContext';
import { SubjectsRegistry } from './registry/SubjectsRegistry';
import { CartPolicy } from './type-policies/CartPolicy/CartPolicy';
import { createAuthLink } from './utils/createAuthLink';

export type DataAccessLayerOptions = {
  logger?: Logger;
  serverUrl: string;
  authOptions: {
    jwtToken: () => Promise<string>;
    logout: () => Promise<void>;
    login: () => Promise<void>;
  };
};

class DataAccessLayer {
  private readonly _apolloClient: ApolloClient<NormalizedCacheObject>;
  private readonly _subjectsRegistry: SubjectsRegistry = new SubjectsRegistry();
  private _logger?: Logger;

  private _bootstrapInstance: BootstrapDataContext | null = null;
  private _checkoutInstance: CheckoutDataContext | null = null;
  private _claimsInstance: ClaimsDataContext | null = null;
  private _footerInstance: FooterDataContext | null = null;
  private _infoInstance: InfoDataContext | null = null;
  private _invoicesInstance: InvoicesDataContext | null = null;
  private _launchWindowsInstance: LaunchWindowsDataContext | null = null;
  private _ordersInstance: OrdersDataContext | null = null;
  private _promotionsInstance: PromotionsDataContext | null = null;
  private _replenishmentInstance: ReplenishmentDataContext | null = null;
  private _topbarInstance: TopbarDataContext | null = null;
  private _upcomingDeliveriesInstance: UpcomingDeliveriesDataContext | null = null;
  private _userInstance: UserDataContext | null = null;
  private _utilsInstance: UtilsDataContext | null = null;

  constructor(options: DataAccessLayerOptions) {
    const { logger } = options;

    if (logger) {
      this._logger = logger;
    }

    const typePolicies = mergeDeep(
      CartPolicy.TypePolicies,
      ProductPolicy.TypePolicies,
      BootstrapDataContext.TypePolicies,
      FooterDataContext.TypePolicies,
      InfoDataContext.TypePolicies,
      InvoicesDataContext.TypePolicies,
      OrdersDataContext.TypePolicies,
      UpcomingDeliveriesDataContext.TypePolicies,
      UserDataContext.TypePolicies,
      UtilsDataContext.TypePolicies
    );

    const errorLink = onError(({ graphQLErrors }) => {
      if (graphQLErrors) {
        for (const graphQLError of graphQLErrors) {
          normalizeGraphQLError(graphQLError);
          this._logger?.error(graphQLError, {
            stackTrace: graphQLError,
          });
        }
      }
    });
    const loggerLink = new ApolloLink((operation, forward) => {
      const currentTransaction = ApmService.instance?.getCurrentTransaction();
      const span = currentTransaction?.startSpan(
        `Operation: ${operation.operationName}, Called with: ${JSON.stringify(
          operation.variables
        )}`,
        'graphql',
        {
          blocking: true,
        }
      );

      return forward(operation).map((data) => {
        span?.end();

        return data;
      });
    });

    const httpLink = new HttpLink({
      uri: options.serverUrl,
    });

    this._apolloClient = new ApolloClient({
      link: ApolloLink.from([
        loggerLink,
        errorLink,
        createAuthLink(options.authOptions.jwtToken),
        httpLink,
      ]),
      cache: new InMemoryCache({
        typePolicies,
        fragments: createFragmentRegistry(
          ...CartPolicy.Fragments,
          ...ProductPolicy.Fragments,
          ...PromotionsDataContext.Fragments
        ),
      }),
      devtools: {
        enabled: true,
      },
    });
  }

  public setLogger(logger: Logger) {
    if (this._logger) {
      // eslint-disable-next-line no-console
      console.warn(`Overwriting old logger!`);
    }

    this._logger = logger;
  }

  public get bootstrap() {
    if (!this._bootstrapInstance) {
      this._logger && this._logger.debug('Creating BootstrapDataContext');
      this._bootstrapInstance = new BootstrapDataContext(
        this._apolloClient,
        this._subjectsRegistry
      );
    }

    return this._bootstrapInstance;
  }

  public get checkout() {
    if (!this._checkoutInstance) {
      this._logger && this._logger.debug('Creating CheckoutDataContext');
      this._checkoutInstance = new CheckoutDataContext(this._apolloClient, this._subjectsRegistry, {
        cartPolicy: true,
      });
    }

    return this._checkoutInstance;
  }

  public get claims() {
    if (!this._claimsInstance) {
      this._logger && this._logger.debug('Creating ClaimsDataContext');
      this._claimsInstance = new ClaimsDataContext(this._apolloClient, this._subjectsRegistry);
    }

    return this._claimsInstance;
  }

  public get footer() {
    if (!this._footerInstance) {
      this._logger && this._logger.debug('Creating FooterDataContext');
      this._footerInstance = new FooterDataContext(this._apolloClient, this._subjectsRegistry);
    }

    return this._footerInstance;
  }

  public get info() {
    if (!this._infoInstance) {
      this._logger && this._logger.debug('Creating InfoDataContext');
      this._infoInstance = new InfoDataContext(this._apolloClient, this._subjectsRegistry);
    }

    return this._infoInstance;
  }

  public get invoices() {
    if (!this._invoicesInstance) {
      this._logger && this._logger.debug('Creating InvoicesDataContext');
      this._invoicesInstance = new InvoicesDataContext(this._apolloClient, this._subjectsRegistry);
    }

    return this._invoicesInstance;
  }

  public get launchWindows() {
    if (!this._launchWindowsInstance) {
      this._logger && this._logger.debug('Creating LaunchWindowsDataContext');
      this._launchWindowsInstance = new LaunchWindowsDataContext(
        this._apolloClient,
        this._subjectsRegistry,
        {
          cartPolicy: true,
        }
      );
    }

    return this._launchWindowsInstance;
  }

  public get orders() {
    if (!this._ordersInstance) {
      this._logger && this._logger.debug('Creating OrdersDataContext');
      this._ordersInstance = new OrdersDataContext(this._apolloClient, this._subjectsRegistry);
    }

    return this._ordersInstance;
  }

  public get promotions() {
    if (!this._promotionsInstance) {
      this._logger && this._logger.debug('Creating PromotionsDataContext');
      this._promotionsInstance = new PromotionsDataContext(
        this._apolloClient,
        this._subjectsRegistry,
        {
          cartPolicy: true,
        }
      );
    }

    return this._promotionsInstance;
  }

  public get replenishment() {
    if (!this._replenishmentInstance) {
      this._logger && this._logger.debug('Creating ReplenishmentDataContext');
      this._replenishmentInstance = new ReplenishmentDataContext(
        this._apolloClient,
        this._subjectsRegistry,
        {
          cartPolicy: true,
        }
      );
    }

    return this._replenishmentInstance;
  }

  public get topbar() {
    if (!this._topbarInstance) {
      this._logger && this._logger.debug('Creating TopbarDataContext');
      this._topbarInstance = new TopbarDataContext(this._apolloClient, this._subjectsRegistry);
    }

    return this._topbarInstance;
  }

  public get upcomingDeliveries() {
    if (!this._upcomingDeliveriesInstance) {
      this._logger && this._logger.debug('Creating UpcomingDeliveriesDataContext');
      this._upcomingDeliveriesInstance = new UpcomingDeliveriesDataContext(
        this._apolloClient,
        this._subjectsRegistry
      );
    }

    return this._upcomingDeliveriesInstance;
  }

  public get user() {
    if (!this._userInstance) {
      this._logger && this._logger.debug('Creating UserDataContext');
      this._userInstance = new UserDataContext(this._apolloClient, this._subjectsRegistry);
    }

    return this._userInstance;
  }

  public get utils() {
    if (!this._utilsInstance) {
      this._logger && this._logger.debug('Creating UtilsDataContext');
      this._utilsInstance = new UtilsDataContext(this._apolloClient, this._subjectsRegistry);
    }

    return this._utilsInstance;
  }

  /**
   * The React bindings needs the apolloClient from here, otherwise it'll try to use the one in the ApolloContext
   */
  public get apolloClient() {
    return this._apolloClient;
  }
}

export default DataAccessLayer;
