import { gql } from '@apollo/client';

export const CART_WITH_SIMULATION_DETAILS = gql`
  query cartWithSimulationDetails(
    $customerId: Int!
    $shipToId: Int!
    $requestedDeliveryDate: String!
    $cartReference: CartReferenceInput!
  ) {
    cartWithSimulationDetails(
      customerId: $customerId
      shipToId: $shipToId
      requestedDeliveryDate: $requestedDeliveryDate
      cartReference: $cartReference
    ) {
      ... on CartWithSimulationDetails {
        id
        items {
          product {
            materialId
            ... on Box {
              itemNumber
            }
            name
            theme
            piecesPerCasePack
            price {
              currency
              grossPrice
              estimatedNetInvoicedPrice
              netInvoicedPrice
            }
            openOrderInfo {
              shippingDate
              shippingPieces
              totalPendingPieces
            }
          }
          quantity
          expectedDeliveryDate
        }
        removedItems {
          ...removedCartItem
        }
        deliveryDates {
          earliestDate
          latestDate
          rddNotAvailableDates {
            startDate
            endDate
          }
        }
        totalSimulationPrice {
          amount
          currency
        }
        totalListPrice {
          amount
          currency
        }
        details {
          totalDiscount {
            amount
            currency
          }
          totalItemSurcharge {
            amount
            currency
          }
        }
        ...cartWithSimulationKey
      }
      ... on Cart {
        id
        items {
          product {
            materialId
            ... on Box {
              itemNumber
            }
            name
            theme
            piecesPerCasePack
            price {
              currency
              grossPrice
              estimatedNetInvoicedPrice
            }
          }
          quantity
        }
        deliveryDates {
          earliestDate
          latestDate
          rddNotAvailableDates {
            startDate
            endDate
          }
        }
        removedItems {
          ...removedCartItem
        }
        ...cartKey
      }
    }
  }
`;
