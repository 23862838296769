import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { useDataAccessLayer } from '@lego/b2b-unicorn-data-access-layer/react';
import {
  ClaimItemFormFields,
  InvoiceOrderItem,
  useClaim,
} from '@lego/b2b-unicorn-shared/components/Claim';
import { ClaimItemForm } from '@lego/b2b-unicorn-shared/components/Claim/ClaimItemForm';
import { ClaimItemFormContextProvider } from '@lego/b2b-unicorn-shared/components/Claim/ClaimItemFormContext';
import { ClaimItemFromValidationSchema } from '@lego/b2b-unicorn-shared/components/Claim/validation';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { Button, ButtonType } from '@lego/b2b-unicorn-shared/ui';
import React, { Fragment, useCallback, useState } from 'react';

import {
  findClaimByInvoicedItemInClaims,
  getUploadUrl,
  mapClaimItemFormValuesToClaimItem,
} from '../helpers';

type ClaimItemFormActionProps = {
  invoiceItem: InvoiceOrderItem;
};
export const ClaimItemFormAction: React.FC<ClaimItemFormActionProps> = ({ invoiceItem }) => {
  const { id: customerId, locale } = useSelectedCustomer();
  const [showClaimForm, setShowClaimForm] = useState(false);
  const { claimItems, invoiceDetails, setClaimItems } = useClaim();
  const dataAccessLayer = useDataAccessLayer();
  const existingClaimItem = findClaimByInvoicedItemInClaims(invoiceItem, claimItems);
  const {
    add_claim_form_header,
    claim_type_label,
    button_add,
    button_edit,
    button_close,
    button_update,
    expected_price,
    invoiced_price,
    claimed_pieces,
    invoiced_pieces,
    dropdown_placeholder,
    file_upload_drag_and_drop,
    file_upload_browse_files,
    upload_documentation,
    required_fields,
    price_disputes,
    damaged_goods,
    delivery_shortage,
    over_under_sub,
    comments,
    file_upload_file_status_cancelled,
    file_upload_file_status_failed,
    file_upload_file_status_filesizeexceeded,
    file_upload_file_status_unsupported,
  } = useLabels();

  const handlePreFileUpload = useCallback(
    getUploadUrl(dataAccessLayer, customerId, invoiceDetails.invoiceNumber),
    [dataAccessLayer, customerId, invoiceDetails.invoiceNumber]
  );

  const handleOnSubmit = (formValues: ClaimItemFromValidationSchema) => {
    const newClaimItem = mapClaimItemFormValuesToClaimItem(formValues, invoiceItem);
    // Since one invoice can consist of multiple orders with the same item, we need to find the correct claim item for invoiced item
    if (!existingClaimItem) {
      setClaimItems([...claimItems, newClaimItem]);
    } else {
      const updatedClaimItems = claimItems.map((item) => {
        if (item.invoiceLineNumber === invoiceItem.invoiceLineNumber) {
          return newClaimItem;
        }
        return item;
      });
      setClaimItems(updatedClaimItems);
    }
    setShowClaimForm(false);
  };

  const translations: React.ComponentProps<typeof ClaimItemFormContextProvider>['translations'] = {
    header: add_claim_form_header,
    buttons: { close: button_close, addClaim: button_add, updateClaim: button_update },
    labels: {
      claimType: claim_type_label,
      claimedPieces: claimed_pieces,
      expectedPrice: expected_price,
      comment: comments,
      uploadDocumentation: upload_documentation,
    },
    helperTexts: {
      claimType: dropdown_placeholder,
      claimedPieces: invoiced_pieces,
      expectedPrice: invoiced_price,
      uploadDocumentationDragAndDrop: file_upload_drag_and_drop,
      uploadDocumentationBrowse: file_upload_browse_files,
      uploadFileStatusCancelled: file_upload_file_status_cancelled,
      uploadFileStatusFailed: file_upload_file_status_failed,
      uploadFileStatusUnsupported: file_upload_file_status_unsupported,
      uploadFileStatusFileSizeExceeded: file_upload_file_status_filesizeexceeded,
      requiredFields: required_fields,
    },
    claimTypeOptions: {
      PRICE_DISPUTES: price_disputes,
      DAMAGED_GOODS: damaged_goods,
      DELIVERY_SHORTAGE: delivery_shortage,
      OVER_UNDER_SUB: over_under_sub,
    },
  };
  return (
    <Fragment>
      <Button
        type={existingClaimItem ? ButtonType.PRIMARY_INVERTED : ButtonType.SECONDARY}
        size={'small'}
        onClick={() => setShowClaimForm(true)}
      >
        {existingClaimItem ? button_edit : button_add}
      </Button>
      {showClaimForm && (
        <ClaimItemFormContextProvider
          translations={translations}
          locale={locale}
          invoiceItem={invoiceItem}
          claimItem={existingClaimItem}
          onSubmit={handleOnSubmit}
        >
          <ClaimItemForm
            open={showClaimForm}
            onClose={() => setShowClaimForm(false)}
          >
            <ClaimItemFormFields
              getFileUploadUrl={handlePreFileUpload}
              claimItem={existingClaimItem}
            />
          </ClaimItemForm>
        </ClaimItemFormContextProvider>
      )}
    </Fragment>
  );
};
