import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { CartProduct } from '@lego/b2b-unicorn-data-access-layer';
import { Price } from '@lego/b2b-unicorn-shared/components/Price';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import {
  Button,
  ButtonType,
  Icon,
  IconType,
  TableCell,
  TableCellItemNumber,
  TableCellName,
  TableCellNumber,
  TableImageWrapperInCell,
  TableRow,
} from '@lego/b2b-unicorn-shared/ui';
import { removeCellStyle, useCartItemRow } from '@lego/b2b-unicorn-ui-checkout-flow';
import { OpenOrderIndicator, ProductImage } from '@lego/b2b-unicorn-ui-components';
import { getImageUrl, ImageType } from '@lego/b2b-unicorn-ui-utils';
import { deepEqual } from 'fast-equals';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ProductName } from '../ProductName/ProductName';
import CartPreviewItemCounter from './CartPreviewItemCounter';
import { tableRowOrderStyle } from './CartPreviewItemRowStyles';

interface ICartPreviewItemRow {
  product: CartProduct;
  quantity: number;
  onUpdate: (product: CartProduct, newQuantity: number) => void;
  onRemove: (product: CartProduct) => void;
  updateError: boolean;
}

const CartPreviewItemRow = React.memo(
  ({ product, quantity, onUpdate, onRemove, updateError }: ICartPreviewItemRow) => {
    const {
      theme: theme_label,
      price_per_piece,
      total_pieces,
      your_price,
      open_order_dialog_header,
      delivery_date_expected_next,
      total_pending,
      pcs,
      pcs_by,
      snackbar_update_product_error,
      recommended_retail_price,
    } = useLabels();
    const history = useHistory();

    const imageUrl = getImageUrl(product.materialId, ImageType.MAIN_BOX, 30);
    const selectedCustomer = useSelectedCustomer();

    const { handleCounterUpdate, totalPieces } = useCartItemRow({
      onUpdate,
      updateError,
      quantity,
      product,
      snackbar_error_message: snackbar_update_product_error,
    });

    const handleOOIClick = () => {
      if (product.__typename !== 'Box' || !product.itemNumber) {
        return;
      }
      const upcomingDeliveryUrl = new URL('/manage/upcoming-deliveries', window.location.origin);
      upcomingDeliveryUrl.searchParams.append('itemQuery', product.itemNumber.toString());

      history.push(`${upcomingDeliveryUrl.pathname}${upcomingDeliveryUrl.search}`);
    };

    return (
      <TableRow extraCss={tableRowOrderStyle}>
        <TableCellItemNumber>
          <TableImageWrapperInCell>
            <ProductImage
              src={imageUrl}
              alt="box image"
            />
          </TableImageWrapperInCell>
          {product.__typename === 'Box' && product.itemNumber}
        </TableCellItemNumber>
        <TableCellName>
          <ProductName
            materialId={product.materialId}
            name={product.name}
          />
          {product.openOrderInfo && (
            <OpenOrderIndicator
              shippingDate={product.openOrderInfo.shippingDate}
              shippingPieces={product.openOrderInfo.shippingPieces}
              totalPendingPieces={product.openOrderInfo.totalPendingPieces}
              translations={{
                open_order_dialog_header,
                delivery_date_expected_next,
                total_pending,
                pcs,
                pcs_by,
              }}
              locale={selectedCustomer.locale}
              isSmall
              onClick={handleOOIClick}
            />
          )}
        </TableCellName>
        <TableCell colName={theme_label}>{product.theme}</TableCell>
        <TableCell width={146}>
          <CartPreviewItemCounter
            quantity={quantity}
            onChange={handleCounterUpdate}
          />
        </TableCell>
        <TableCellNumber
          colName={total_pieces}
          align={'right'}
        >
          {totalPieces}
        </TableCellNumber>
        <TableCellNumber
          colName={recommended_retail_price}
          align={'right'}
        >
          {product.__typename === 'Box' && (
            <Price
              locale={selectedCustomer.locale}
              fallbackValue={''}
            >
              {product.recommendedRetailPrice}
            </Price>
          )}
        </TableCellNumber>
        <TableCellNumber colName={price_per_piece}>
          <Price
            locale={selectedCustomer.locale}
            fallbackValue={''}
          >
            {product.price}
          </Price>
        </TableCellNumber>
        <TableCellNumber colName={your_price}>
          <Price
            locale={selectedCustomer.locale}
            fallbackValue={''}
            multiplier={totalPieces}
          >
            {product.price}
          </Price>
        </TableCellNumber>
        <td css={removeCellStyle}>
          <Button
            type={ButtonType.GHOST}
            size="small"
            onClick={() => onRemove(product)}
          >
            <Icon type={IconType.DELETE} />
          </Button>
        </td>
      </TableRow>
    );
  },
  (prevProps, nextProps) => {
    return deepEqual(prevProps, nextProps);
  }
);
CartPreviewItemRow.displayName = 'CartPreviewItemRow';

export default CartPreviewItemRow;
